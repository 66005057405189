import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';


import { FileUploadModule } from '@app/primeng-overrides/fileupload';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';

import { GlobalModule } from '@app/_global/global.module';
import { Abonne } from '@app/abonne/abonne.model';

@Component({
	selector: 'logo-display',
	template: `
		<img [src]="url" [ngStyle]="{width: width, height: height}"
			*ngIf="abonne.abo_fichier_logo"
		>
	`
})
export class AbonneLogoDisplayComponent implements OnInit {

	@Input() abonne: Abonne;
	@Input() width: string = '100%';
	@Input() height: string = '100%';

	url: string = '';

	constructor() {
	}

	ngOnInit(): void {
		this.url = `/logo/${this.abonne.abo_id}/${this.abonne.abo_fichier_logo}`
	}

}

@NgModule({
	imports: [
		CommonModule,
		ButtonModule,
		FileUploadModule,
		PanelModule,
		TooltipModule,
		GlobalModule
	],
	exports: [AbonneLogoDisplayComponent],
	declarations: [AbonneLogoDisplayComponent]
})
export class AbonneLogoDisplayModule { }

