import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
	TranslateModule,
} from "@codeandweb/ngx-translate";

import { UIRouterModule } from '@uirouter/angular';

import { ParametreService } from '@app/parametre/parametre.service';
import { GlobalModule } from '@app/_global/global.module';

@Component({
	templateUrl: 'auth.component.html'
})
export class AuthComponent implements OnInit {

	instanceName: string;
	frontPage: string;
	mailtoHelp: string;
	displayAppLogo: boolean = false;
	display_instance_name_on_auth_page: boolean = false;

	constructor(private parametreService: ParametreService) {
		this.instanceName = this.parametreService.getParam('instance_name');
		this.frontPage = this.parametreService.getParam('front_page');
		this.mailtoHelp = this.parametreService.getParam('mailto_help');
		this.displayAppLogo = this.parametreService.getParam('display_app_logo');
		this.display_instance_name_on_auth_page = this.parametreService.getParam('display_instance_name_on_auth_page');
	}

	ngOnInit() { }

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		TranslateModule,
	],
	exports: [AuthComponent],
	declarations: [AuthComponent]
})
export class AuthModule { }
