<p-panel class="no-header-panel">

	<div class="flex flex-column">
		<!-- Liste des abonnés (vue de l'admin) -->
		<form class="mb-2" #abonnesForm="ngForm" *ngIf="!selfEditing && isAdminView">
			<div class="flex flex-column">

				<button
					*ngIf="!editMode"
					type="button"
					pButton
					class="label-md-none w-max"
					[label]="'users.link_to_abonne_button_label' | translate"
					icon="pi pi-link"
					(click)="openAbonneLinkDialog()"
				></button>

				<div class="flex flex-row flex-wrap mt-4">
					<p-card class="col-6 lg:col-4" *ngFor="let abonne of utilisateur.abonnes">

						<div class="text-xl flex flex-row align-items-center">
							<div *ngIf="abonne.abo_fichier_logo" class="mr-2">
								<logo-display [abonne]="abonne" width="3rem"></logo-display>
							</div>

							<span>{{abonne.abo_nom_abonne}}</span>
							<div class="col-4 flex flex-row align-items-center">
								<a uiSref="abonnes.single" [uiParams]="{abo_id: abonne.abo_id}">
									<button
										type="button"
										pButton
										icon="pi pi-eye"
									></button>
								</a>
								<button
									type="button"
									pButton
									icon="pi pi-times-circle"
									class="p-button-rounded p-button-text p-button-danger"
									(click)="unlinkAbonne(abonne)"
								></button>
							</div>
						</div>

						<form class="p-fluid formgrid grid mt-3">

							<div class="field col-6">
								<label>
									{{ 'abonnes.access_level_label' | translate }}
								</label>

								<niveau-access-selector
									name="nac_id"
									[(ngModel)]="abonne.nac_id"
									(ngModelChange)="editUtilisateurAbonne(abonne.abo_id, abonne)"
									[placeholder]="'abonnes.access_level_label' | translate"
									[readonly]="!canEditAccessLevelForAbonne(abonne)"
								>
								</niveau-access-selector>
							</div>

							<div class="field col-6">
								<label>
									{{ 'abonnes.subscription_management_right_label' | translate }}
								</label>

								<br/>

								<p-checkbox
									class="mt-2"
									name="utb_gestion_abonnement"
									inputId="utb_gestion_abonnement"
									[(ngModel)]="abonne.utb_gestion_abonnement"
									(ngModelChange)="editUtilisateurAbonne(abonne.abo_id, abonne)"
									[readonly]="!canEditGestionAbonnementForAbonne(abonne)"
									[binary]="true"
								></p-checkbox>
							</div>
						</form>
					</p-card>
				</div>
			</div>
		</form>
	</div>

</p-panel>