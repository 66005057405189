export const gestionnaireAbonneMenuItems = [
	{
		labelKey: 'menu.users',
		icon: 'pi pi-users',
		state: 'utilisateurs',
	},

	{
		labelKey: 'menu.sites',
		icon: 'pi pi-map-marker',
		state: 'sites',
	},

	{
		labelKey: 'menu.abonnement',
		icon: 'pi pi-info-circle',
		state: 'abonnement',
	}
];
