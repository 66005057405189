import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { FamilleService } from '@app/famille/famille.service';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';
import { loadTranslation } from '@app/_helpers/utils';


@Component({
	selector: 'famille-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FamilleSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="familles"
			[(ngModel)]="value"
			[readonly]="readonly"
			[ngClass]="{'p-readonly': readonly}"
			[placeholder]="_placeholder"
			[disabled]="disabled"
			[showClear]="nullOption"
			optionValue="fam_id"
			optionLabel="fam_nom"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class FamilleSelectorComponent extends ControlValueAccessorBase<number> implements OnInit  {

	@Input() name: string;
	@Input('value') innerValue: number;
	@Input() required: boolean;
	@Input() readonly: boolean;
	@Input() placeholder: string;
	@Input() nullIfInvalid: boolean;
	@Input() nullOption: boolean;
	@Input() disabled: boolean;
	@Input() unavailableFamilles?: number[];

	_placeholder: string = 'Sélectionnez';

	familles: {fam_id: number, fam_nom: string}[] = [];

	constructor(private familleService: FamilleService, private translateService: TranslateService) {
		super();

		loadTranslation(translateService, 'common.selector_placeholder', (translation: string) => {
			this._placeholder = translation;
		})
	}

	ngOnInit() {
		if (this.placeholder != undefined) {
			this._placeholder = this.placeholder;
		}

		this.familleService.getFamilles({})
		.subscribe(({ familles }) => {
			this.familles = familles.filter(famille => famille.fam_actif);

			if (this.unavailableFamilles != undefined) {
				this.familles = this.familles.filter(famille => !this.unavailableFamilles?.includes(famille.fam_id))
			}
		});
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		AutoCompleteModule,
		DropdownModule
	],
	exports: [FamilleSelectorComponent],
	declarations: [FamilleSelectorComponent]
})
export class FamilleSelectorModule { }
