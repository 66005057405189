import { Injectable, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/core';
import { HttpClient } from '@angular/common/http';
import { IEventListener, EventManagerService } from '@global/event-manager.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { ParametreService } from '@app/parametre/parametre.service';
import { StorageService } from '@global/storage.service';

import { isEmpty, uid } from '@helpers/utils';

import * as JsEncryptModule from 'jsencrypt';

export class Token {
	token: string;
	refreshToken?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService implements IEventListener, OnDestroy {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private tokenSubject = new BehaviorSubject<Token|null>(null);
	readonly currentToken = this.tokenSubject.asObservable();
	private refreshTokenTimeout: any;

	constructor(
		private eventManager: EventManagerService,
		private http: HttpClient,
		private parametreService: ParametreService,
		private stateService: StateService,
		private storageService: StorageService,
	) {
		let tmpToken;
		if (this.isRemembered()) {
			tmpToken = localStorage.getItem('token');
		}
		else {
			tmpToken = sessionStorage.getItem('token');
		}
		if (tmpToken) {
			try {
				this.tokenSubject.next(JSON.parse(tmpToken));
			}
			catch(e) {
				console.log('Invalid token from session storage')
				this.tokenSubject.next(null);
			}
		}

		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.logout(args);
			}
		);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	public isRemembered() {
		return !!localStorage.getItem('token');
	}

	public get token(): Token|null {
		return this.tokenSubject.getValue();
	}

	public extractJWTVAlue(jwt: string, attr: string, defaultValue?: any) {
		const token: any = JSON.parse(atob(jwt.split('.')[1]));
		if (typeof token[attr] != 'undefined') {
			return token[attr];
		}
		return (typeof defaultValue != 'undefined')? defaultValue : undefined;
	}

	public extractCurrentJWTVAlue(attr: string, defaultValue?: any) {
		if (this.token && this.token.token) {
			return this.extractJWTVAlue(this.token.token, attr, defaultValue);
		}
		return undefined;
	}

	private startRefreshTokenTimer() {
		this.stopRefreshTokenTimer();
		if (this.token && this.token.token) {
			console.log('startRefreshTokenTimer');
			// parse json object from base64 encoded jwt token
			const expirationEpochSec = this.extractJWTVAlue(this.token.token, 'exp');
			// calculate expiration timeout
			const expires = new Date(expirationEpochSec * 1000);
			let timeout = expires.getTime() - Date.now();
			if (timeout < 5000) {
				console.log(`Expiration is less than minimal timeout: ${timeout}/${5000}`, 'Attempt refresh immediately');
				this.refreshToken().subscribe();
			}
			else {
				// refresh token before it expires
				timeout = timeout - 5000;
				this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
				console.log(`Will refresh token in ${timeout/1000}s`, new Date(Date.now() + timeout));
			}
		}
		else {
			console.log('startRefreshTokenTimer', 'No token, timer NOT started');
		}
	}

	private stopRefreshTokenTimer() {
		clearTimeout(this.refreshTokenTimeout);
	}

	login(email: string, password: string, remember_me: boolean) {
		const device_uid: string|null = localStorage.getItem('device_uid');
		return this.parametreService.getPublicKey()
		.pipe(
			mergeMap(
				(pubKey: string) => {
					let tmpPassword: string = password;
					let password_encrypted: boolean = false;
					if (pubKey) {
						password_encrypted = true;
						const encrypt = new JsEncryptModule.JSEncrypt();
						encrypt.setPublicKey(pubKey);
						tmpPassword = encrypt.encrypt(password);
					}
					const payload = {
						email: email,
						password: tmpPassword,
						password_encrypted: password_encrypted,
						device_uid: device_uid
					};
					return this.http.post<any>(`/auth/token`, payload)
					.pipe(
						map((token: Token) => {
							this.storageService.useLocalStorage = remember_me;
							this.storageService.set('token', token);
							const device_uid: string|undefined = this.extractJWTVAlue(token.token, 'device_uid');
							if (typeof device_uid != 'undefined') {
								localStorage.setItem('device_uid', device_uid);
							}
							this.tokenSubject.next(token);
							// this.startRefreshTokenTimer();
							return token;
						})
					);
				}
			)
		);
	}

	logout(manual?: any) {
		// close all modals, just in case
		this.eventManager.emit('close-modals');

		if (this.hasToken()) {
			if (typeof manual == 'undefined' || isEmpty(manual)) {
				this.eventManager.emit('toast', {severity: 'info', summary: 'Session expirée'});
			}
			// this.http.post<any>(`/auth/revoke-token`, {}, { withCredentials: true }).subscribe();
		}

		this.stopRefreshTokenTimer();
		this.storageService.reset();
		this.tokenSubject.next(null);
		if (!this.stateService.includes('auth') || this.stateService.includes('auth.challenge_2fa')) {
			if (isEmpty(manual)) {
				this.stateService.go('auth.login', undefined, {location: false} );
			}
			else {
				this.stateService.go('auth.login', undefined, {reload: true, custom: 'manual'});
			}
		}
	}

	hasToken(): boolean {
		if (this.token && this.token.token) {
			return true;
		}
		// console.log('no currentToken in storage');
		return false;
	}

	refreshToken() {
		const currentToken = this.token;
		if (currentToken) {
			return this.http.post<any>(`/auth/token/rafraichir`, {refreshToken: currentToken.refreshToken}, { withCredentials: true })
			.pipe(map((token) => {
				if (this.isRemembered()) {
					localStorage.setItem('token', JSON.stringify(token));
				}
				else {
					sessionStorage.setItem('token', JSON.stringify(token));
				}

				this.tokenSubject.next(token);
				this.startRefreshTokenTimer();
				return token;
			}));
		}
		else {
			return new Observable(subscriber => {
				this.logout();
				subscriber.error('no token, no refresh');
			});
		}
	}

	getPublicKey() {
		return this.http.get<any>(`/auth/changement_mot_de_passe/cle_encryption`);
	}

	reinitialisationMotDePasse(email: string) {
		return this.http.post<any>(`/auth/reinitialisation_mot_de_passe`, { email: email });
	}

	changementMotDePasse(password: string, passwordConfirm: string, token: string) {
		return this.parametreService.getPublicKey()
		.pipe(
			mergeMap((pubKey: string) => {
				let tmpPassword: string = password;
				let tmpPasswordConfirm: string = passwordConfirm;
				let password_encrypted: boolean = false;
				if (pubKey) {
					password_encrypted = true;
					const encrypt = new JsEncryptModule.JSEncrypt();
					encrypt.setPublicKey(pubKey);
					tmpPassword = encrypt.encrypt(password);
					tmpPasswordConfirm = encrypt.encrypt(passwordConfirm);
				}
				const payload = {
					password_encrypted: password_encrypted,
					password: tmpPassword,
					password_confirmation: tmpPasswordConfirm,
					token: token
				};
				return this.http.post<any>(`/auth/reinitialisation_mot_de_passe/reset`, payload);
			})
		);
	}

	changePasswordConnected(currentPassword: string, password: string, passwordConfirm: string) {
		return this.parametreService.getPublicKey()
		.pipe(
			mergeMap((pubKey: string) => {
				let tmpCurrentPassword: string = currentPassword;
				let tmpPassword: string = password;
				let tmpPasswordConfirm: string = passwordConfirm;
				let password_encrypted: boolean = false;
				if (pubKey) {
					password_encrypted = true;
					const encrypt = new JsEncryptModule.JSEncrypt();
					encrypt.setPublicKey(pubKey);
					tmpCurrentPassword = encrypt.encrypt(currentPassword);
					tmpPassword = encrypt.encrypt(password);
					tmpPasswordConfirm = encrypt.encrypt(passwordConfirm);
				}
				const payload = {
					password_encrypted: password_encrypted,
					current_password: tmpCurrentPassword,
					new_password: tmpPassword,
					password_confirmation: tmpPasswordConfirm,
				};
				return this.http.post<any>(`/auth/changement_mot_de_passe_sans_token`, payload);
			})
		);
	}

	challenge2fa(code: string) {
		return this.http.post<any>(`/auth/2fa/challenge`, {
			code_2fa: code,
			remember_me: this.isRemembered()
		})
		.pipe(
			map((token: Token) => {
				this.storageService.set('token', token);
				const device_uid: string|undefined = this.extractJWTVAlue(token.token, 'device_uid');
				if (typeof device_uid != 'undefined') {
					localStorage.setItem('device_uid', device_uid);
				}
				this.tokenSubject.next(token);
				// this.startRefreshTokenTimer();
				return token;
			})
		);
	}

	resend2faCode() {
		return this.http.post<any>(`/auth/2fa/resend_code`, {});
	}

}
