import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { Abonne } from '@app/abonne/abonne.model';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';


@Component({
	selector: 'abonne-abonnement',
	templateUrl: `abonne-abonnement.component.html`
})
export class AbonneAbonnementComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input({alias: 'abonne_courant'}) abonne: Abonne;

	isCreation = false;
	canEdit = false;
	editMode = false;

	constructor(private stateService: StateService) {}

	ngOnInit(): void {
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		CalendarModule,
		DividerModule,
		CheckboxModule,
		LogoUploaderModule,
		GzTabMenuModule
	],
	exports: [AbonneAbonnementComponent],
	declarations: [AbonneAbonnementComponent]
})
export class AbonneAbonnementModule { }
