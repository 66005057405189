<p-message
	severity="warn"
	text="Pour des raisons de sécurité, nous demandons aux utilisateurs de changer régulièrement leurs mots de passe.<br><br>L'administrateur de votre société peut définir la durée de renouvellement entre 3 et 12 mois."
	[escape]="false"
	class="p-fluid"
	styleClass="mb-2 text-left"
	*ngIf="expires <= 0"
></p-message>

<p-message
	severity="info"
	text="Merci de compléter le formulaire ci-dessous afin de pouvoir vous connecter avec un nouveau mot de passe."
	class="p-fluid"
	styleClass="mb-5 text-left"
></p-message>

<form class="p-fluid mb-5" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
	<div class="form-group field">
		<label>
			{{ 'auth.type_current_password_label' | translate }}
		</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input type="password" style="display: none;" name="damn-you-autocomplete"/>
			<input
				pInputText
				type="password"
				formControlName="currentPassword"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': f.currentPassword.dirty && f.currentPassword.errors }"
				placeholder="Mot de passe actuel"
			/>
		</div>
		<div *ngIf="f.currentPassword.dirty && f.currentPassword.errors" class="invalid-feedback text-color-danger">
			<small *ngIf="f.currentPassword.errors.required">
				{{ 'auth.current_password_required' | translate }}
			</small>
		</div>
	</div>
	<div class="form-group field">
		<label>
			{{ 'auth.type_new_password' | translate }}
		</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input type="password" style="display: none;" name="damn-you-autocomplete2"/>
			<p-password
				formControlName="password"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': f.password.dirty && f.password.errors }"
				placeholder="Nouveau mot de passe"
				promptLabel="Saisissez votre nouveau mot de passe"
				appendTo="body"
				(ngModelChange)="checkCriterias()"
			>
			</p-password>
		</div>
		<div>
			<small>
				Le mot de passe doit :
				<ul class="pl-2 ml-2 mt-0">
					<li [ngClass]="{
						'text-color-success':
							f.password.dirty
							&& f.currentPassword.value != f.password.value,
						'text-color-danger':
							f.password.dirty
							&& f.currentPassword.value == f.password.value
					}">doit être différent du mot de passe actuel</li>
					<li [ngClass]="{'text-color-success': f.password.dirty && f.password.valid, 'text-color-danger': f.password.errors && f.password.errors.pattern}">être suffisamment complexe</li>
					<li [ngClass]="{'text-color-success': criterias.nb_chars.result == 1, 'text-color-danger': criterias.nb_chars.result == 0}">contenir au minimum 8 caractères</li>
					<li [ngClass]="{'text-color-success': criterias.uppercase.result == 1, 'text-color-danger': criterias.uppercase.result == 0}">contenir au minimum une majuscule</li>
					<li [ngClass]="{'text-color-success': criterias.lowercase.result == 1, 'text-color-danger': criterias.lowercase.result == 0}">contenir au minimum une minuscule</li>
					<li [ngClass]="{'text-color-success': criterias.nb_numbers.result == 1, 'text-color-danger': criterias.nb_numbers.result == 0}">contenir au minimum un chiffre</li>
				</ul>
			</small>
		</div>
	</div>
	<div class="form-group field">
		<label>Confirmation nouveau mot de passe</label>
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon"><i class="pi pi-key"></i></span>
			<input
				pInputText
				type="password"
				formControlName="passwordConfirm"
				[attr.disabled]="loading? true : null"
				[ngClass]="{ 'p-invalid': submitted && f.passwordConfirm.errors }"
				placeholder="Confirmation mot de passe"
			/>
		</div>
		<div *ngIf="f.passwordConfirm.dirty && f.passwordConfirm.errors" class="text-color-danger">
			<small *ngIf="f.passwordConfirm.errors.required">Confirmation du mot de passe requise</small>
			<small class="text-color-danger" *ngIf="f.passwordConfirm.errors.mustMatch">Le nouveau mot de passe et sa confirmation sont différents</small>
		</div>
	</div>

	<p-message severity="error" text="{{error}}" *ngIf="error && !loading"></p-message>

	<button
		type="submit"
		pButton
		label="Changer de mot de passe"
		[disabled]="!changePasswordForm.valid || f.currentPassword.value == f.password.value || loading"
	></button>

	<p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>

	<div class="mb-2"></div>
</form>

<div class="text-center">
	<small>
		<a
			class="p-button p-button-sm p-button-text p-button-plain"
			uiSref="auth.reset_password"
			[innerHTML]="'auth.click_here_if_forgotten_current_password' | translate">
		</a>
		</small>
</div>
