import { Observable } from 'rxjs';
import { clone } from './utils';

export class Cache<T> {
	protected cached?: T = undefined;

	constructor() {}

	isEmpty() {
		return this.cached === undefined;
	}

	isNotEmpty() {
		return !this.isEmpty();
	}

	update(newValue: T) {
		this.cached = newValue;
	}

	// currentAsObservable retourne un observable avec un seul élément dans
	// la séquence: la valeur courrante du cache. isNotEmpty doit être vérifié avant.
	currentAsObservable() {
		const cache = this.cached;

		return new Observable<T>((subscriber) => {
			subscriber.next(cache);
			subscriber.complete();
		});
	}

	get current() {
		return this.cached;
	}

	invalidate() {
		this.cached = undefined;
	}
}

// Cache qui auto expire après une certaine durée spécifiée à la construction (maxAgeMillis).
export class ExpiringCache<T> extends Cache<T> {
	constructor(readonly maxAgeMillis = 1000) {
		super();
	}

	protected timeoutHandle?: ReturnType<typeof setTimeout>;

	override update(newValue: T) {
		this.cached = newValue;
		if (this.timeoutHandle !== undefined) {
			clearTimeout(this.timeoutHandle);
		}
		// Invalidation du cache après maxAgeMillis.
		this.timeoutHandle = setTimeout(() => {
			this.invalidate();
		}, this.maxAgeMillis);
	}
}
