import { NgModule, Component, Input, OnInit, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

@Component({
	selector: 'civilite-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CiviliteSelectorComponent),
			multi: true
		}
	],
	template: `
		<input
			type="text"
			pInputText
			[name]="name"
			[ngModel]="value"
			readonly
			*ngIf="readonly"
		>
		<p-dropdown
			[name]="name"
			#civilite
			[(ngModel)]="value"
			[editable]="true"
			[disabled]="disabled"
			[options]="options"
			placeholder="Sélectionnez ou saisissez une valeur"
			*ngIf="!readonly"
		></p-dropdown>
	`
})
export class CiviliteSelectorComponent implements ControlValueAccessor, OnInit {

	@Input() name: string;
	@Input('value') innerValue: string;
	@Input() readonly: boolean;
	@Input() disabled: boolean;

	options: string[] = [];

	constructor(private utilisateurService: UtilisateurService) {

	}

	ngOnInit() {
		this.options = this.utilisateurService.getCivilites('long');
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: string) {
		// this value is updated by programmatic changes if( val !== undefined && this.val !== val){
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: Function) {
		this.onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this.onTouched = fn;
	}

	writeValue(value: string) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
		InputTextModule,
	],
	exports: [CiviliteSelectorComponent],
	declarations: [CiviliteSelectorComponent]
})
export class CiviliteSelectorModule { }
