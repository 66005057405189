import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { NiveauAccessSelectorModule } from '@app/abonne/niveau-acces-selector';
import { GlobalModule } from '@app/_global/global.module';

import { uid } from '@helpers/utils';

@Component({
	selector: 'abonne-lien-dialog',
	template: `
		<form class="mb-2" #lienAbonneForm="ngForm" (ngSubmit)="submit()">
			<div class="p-fluid formgrid grid">

				<div class="field col-fixed">
					<label>
						{{'abonnes.abonne_label' | translate}}
					</label>
					<abonne-selector
						class="field col-fixed"
						name="abo_id"
						[(ngModel)]="abo_id"
						[placeholder]="'abonnes.abonne_label' | translate"
						[unavailableAbonnes]="unavailableAbonnes"
						required
					></abonne-selector>
				</div>

				<div class="field col-fixed">
					<label>
						{{ 'abonnes.access_level_label' | translate }}
					</label>

					<niveau-access-selector
						name="nac_id"
						[(ngModel)]="nac_id"
						[placeholder]="'abonnes.access_level_label' | translate"
					>
					</niveau-access-selector>
				</div>

				<div class="field col-fixed">
					<label>
						{{ 'abonnes.subscription_management_right_label' | translate }}
					</label>

					<br/>

					<p-checkbox
						class="mt-2"
						name="utb_gestion_abonnement"
						inputId="utb_gestion_abonnement"
						[(ngModel)]="utb_gestion_abonnement"
						[binary]="true"
					></p-checkbox>
				</div>

			</div>

			<!-- Submit -->
			<div class="text-center w-full mt-4">
				<button
					type="submit"
					pButton
					[label]="'abonnes.link_submit_button' | translate"
					[disabled]="!lienAbonneForm.form.valid">
				</button>
			</div>
		</form>
	`,
	providers: []
})
export class AbonneLienDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	abo_id?: number;
	nac_id: number = 1
	utb_gestion_abonnement = false;

	unavailableAbonnes: number[];

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
	) {
		this.unavailableAbonnes = config.data.unavailableAbonnes;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {});
	}

	submit(){
		if (this.abo_id != undefined) {
			this.dialogRef.close({
				abo_id: this.abo_id,
				nac_id: this.nac_id,
				utb_gestion_abonnement: this.utb_gestion_abonnement
			});
		}
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		ButtonModule,
		InputTextareaModule,
		AbonneSelectorModule,
		NiveauAccessSelectorModule
	],
	exports: [AbonneLienDialogComponent],
	declarations: [AbonneLienDialogComponent]
})
export class AbonneLienModule { }

