import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	getParams,
	MatchResult,
	parseUrl,
	StateRule,
	StateService,
	TargetState,
	UrlParts,
	UrlService,
} from '@uirouter/core';

import { EventManagerService } from '@app/_global/event-manager.service';

@Injectable({ providedIn: 'root' })
export class StateUtilsService {

	constructor(
		private eventManager: EventManagerService,
		private stateService: StateService,
		private urlService: UrlService,
	) {
	}

	public getFirstNonAbstractParent(state: any): any {
		let parent: any = this.stateService.get('^', state);
		while (!!parent.abstract || parent.redirectTo == state.name) {
			parent = this.stateService.get('^', parent);
		}
		return parent;
	}

	public matchUrlToState(url: string): TargetState | null {
		// let formattedUrl = this.internalLinkPipe.transform(url);
		let formattedUrl = url;

		const parts: UrlParts = {
			path: parseUrl(formattedUrl).path,
			// search: getParams(parseUrl(formattedUrl).search),
			hash: parseUrl(formattedUrl).hash,
		};

		const best: MatchResult = this.urlService.match(parts);

		const rule = best && best.rule;
		// If the best match is a state, get the params
		if (rule && rule.type === 'STATE') {
			const state = (rule as StateRule).state;
			const params = best.match;

			// reaffect the search params, because... weird...
			if (parseUrl(formattedUrl).search) {
				Object.assign(params, getParams(parseUrl(formattedUrl).search));
			}

			return this.stateService.target(state, params);
		}
		return null;
	}

	public returnToState(returnTo: TargetState) {
		const state = returnTo.state();
		const params = returnTo.params();
		const options = Object.assign({}, returnTo.options(), { reload: true });
		if (!!!state || state.name && state.name.startsWith('auth')) {
			this.stateService.go('root', undefined, { reload: true });
		}
		else {
			this.stateService.go(state, params, options);
		}
	}


	public successOrLogout(apiCall: Observable<any>) {
		return apiCall.toPromise()
			.then(
				(response: any) => {
					return response;
				},
				(error: any) => {
					this.eventManager.emit('logout');
				},
			);
	}

	// To be used in transition hooks
	// Returns a TargetState on error
	public successOrRedirectHook(
		apiCall: Observable<any>,
		ignoreStatusCodes: number[] = [],
		defaultError?: any,
	) {
		return apiCall.toPromise()
			.then(
				(response: any) => {
					return response;
				},
				(error: any) => {
					if (ignoreStatusCodes.includes(error.status)) {
						return false; // TODO false ou bien l'erreur ?
					}
					if (error.error && error.error.data && error.error.data.error_code) {
						return this.stateService.target('service_unavailable', { error: error.error.data.error_code });
					}
					if (error.error && error.error.error_code) {
						return this.stateService.target('service_unavailable', { error: error.error.error_code });
					}
					else if (defaultError) {
						return this.stateService.target('service_unavailable', defaultError);
					}
					else {
						return this.stateService.target('service_unavailable', { error: 'unknown' });
					}
				},
			);
	}

	// To be used in state resolves
	// Redirects on error
	public successOrRedirectResolve(
		apiCall: Observable<any>,
		ignoreStatusCodes: number[] = [],
		defaultError?: any,
	) {
		return apiCall.toPromise()
			.then(
				(response: any) => {
					return response;
				},
				(error: any) => {
					if (ignoreStatusCodes.includes(error.status)) {
						return Promise.reject(error); // TODO reject or resolve error, or ...?
					}
					if (error.error && error.error.data && error.error.data.error_code) {
						return this.stateService.go('service_unavailable', { error: error.error.data.error_code });
					}
					if (error.error && error.error.error_code) {
						return this.stateService.go('service_unavailable', { error: error.error.error_code });
					}
					else if (defaultError) {
						return this.stateService.go('service_unavailable', defaultError);
					}
					else {
						return this.stateService.go('service_unavailable', { error: 'unknown' });
					}
				},
			);
	}
}

