import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

import { map } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { Famille, FamilleAbonne } from './famille.model';

import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import { environment } from '@environments/environment';
import { clone, convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject, uid } from '@helpers/utils';


@Injectable({ providedIn: 'root' })
export class FamilleService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	constructor(
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private http: HttpClient,
		private stateService: StateService,
	) {

	}

	ngOnDestroy(): void {
	}

	public prepareFamilleAbonneFromServer(famille: FamilleAbonne) {
		let tmp: FamilleAbonne = clone(famille);
		convertDateFieldsToDate(tmp);
		return tmp;
	}

	public prepareFamilleAbonneForServer(famille: FamilleAbonne) {
		let tmp = structuredClone(famille) as ExtensibleObject;
		convertDateFieldsToString(tmp);
		return tmp;
	}

	public prepareFamilesAbonneFromServer(familles: FamilleAbonne[]) {
		for (let i = 0; i < familles.length ; i++) {
			familles[i] = this.prepareFamilleAbonneFromServer(familles[i]);
		}
		return familles;
	}

	public getFamilles(params: unknown = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/familles`, tmpParams).pipe(
			map(({ familles, total }: any) => {
				return {familles: familles as Famille[], total: total || 0 as number};
			})
		)
	}

	public getFamille(fam_id: number) {
		return this.http.get<any>(`${environment.api_url}/familles/${fam_id}`).pipe(
			map(response => response as Famille)
		)
	}

	public createFamille(famille: Famille){
		let body: ExtensibleObject = structuredClone(famille);
		delete body.fam_id;

		return this.http.post<any>(`${environment.api_url}/familles`, body);
	}

	public updateFamille(famille: Famille){
		let body: ExtensibleObject = structuredClone(famille);

		return this.http.put<any>(`${environment.api_url}/familles/${famille.fam_id}`, body);
	}

	public deleteFamille(fam_id: number){
		return this.http.delete<any>(`${environment.api_url}/familles/${fam_id}`);
	}


	public linkFamilleToAbonne(fam_id: number, abo_id: number){
		return this.http.post<any>(`${environment.api_url}/abonnes/${abo_id}/familles/${fam_id}`, {});
	}

	public unlinkFamilleFromAbonne(fam_id: number, abo_id: number){
		return this.http.delete<any>(`${environment.api_url}/abonnes/${abo_id}/familles/${fam_id}`);
	}

	public exportFamilles(params: unknown){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/familles/export`, tmpParams);
	}
}
