<p-panel>

	<ng-template pTemplate="header">
		<div class="flex flex-row align-items-center">

			<!-- Titre -->
			<div class="p-panel-title mr-3 ml-1">
				<ng-container *ngIf="isCreation">
					{{ 'abonnes.new_abonne' | translate }}
				</ng-container>

				<ng-container *ngIf="!isCreation">
					{{ initialAbonne.abo_nom_abonne }}
				</ng-container>
			</div>

			<!-- Checkbox actif/inactif -->
			<div class="field-checkbox mb-0 ml-4">
				<p-checkbox
					name="abo_actif"
					inputId="abo_actif"
					[(ngModel)]="abonne.abo_actif"
					[binary]="true"
					[label]="abonne.abo_actif ? ('abonnes.active_state' | translate) : ('abonnes.inactive_state' | translate)"
					[disabled]="!editMode"
				></p-checkbox>
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="icons">
		<a uiSref="utilisateurs" [uiParams]="{selected_abo_id: abonne.abo_id}" class="mr-2">
			<button
				type="button"
				pButton
				icon="pi pi-eye"
				[label]="'abonnes.linked_user_list_label' | translate"
			></button>
		</a>

		<!-- Bouton éditer -->
		<button
			*ngIf="!editMode"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.edition_button_label' | translate"
			icon="pi pi-pencil"
			(click)="toggleEditMode()"
		></button>

		<!-- Bouton enregistrer -->
		<button
			*ngIf="!!editMode"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.save_button_label' | translate"
			icon="pi pi-save"
			(click)="submit()"
			[disabled]="!abonneForm.valid"
		></button>
	</ng-template>

	<form class="mb-2" #abonneForm="ngForm">

		<div class="p-fluid grid formgrid p-2 pt-0 col-12">

			<!-- Information principales (ligne du haut)-->
			<div class="p-fluid grid formgrid p-2 col-12 mb-2">
				<!-- Nom abonné -->
				<div class="field col-fixed">
					<label for="abo_nom_abonne">
						{{ 'common.name_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="abo_nom_abonne"
						[(ngModel)]="abonne.abo_nom_abonne"
						[attr.placeholder]="'common.name_label' | translate"
						[readOnly]="!editMode"
						required
					/>
				</div>

				<!-- Nom société -->
				<div class="field col-fixed">
					<label for="abo_nom_societe">
						{{ 'common.company_name_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="abo_nom_societe"
						[(ngModel)]="abonne.abo_nom_societe"
						[attr.placeholder]="'common.company_name_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>
			</div>

			<!-- Colonne de gauche -->

			<div class="p-fluid grid formgrid p-2 col-6">
				<p-divider class="col-12" align="left">
					<b>{{ 'common.address_label' | translate }}</b>
				</p-divider>

				<!-- Adresse 1 -->
				<div class="field col-fixed">
					<label for="abo_adresse_1">
						{{ 'abonnes.address_1_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="address_1_label"
						[(ngModel)]="abonne.abo_adresse_1"
						[attr.placeholder]="'abonnes.address_1_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>

				<!-- Adresse 2 -->
				<div class="field col-fixed">
					<label for="abo_adresse_2">
						{{ 'abonnes.address_2_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="address_2_label"
						[(ngModel)]="abonne.abo_adresse_2"
						[attr.placeholder]="'abonnes.address_2_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>

				<!-- Adresse 3 -->
				<div class="field col-fixed">
					<label for="abo_adresse_3">
						{{ 'abonnes.address_3_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="address_3_label"
						[(ngModel)]="abonne.abo_adresse_3"
						[attr.placeholder]="'abonnes.address_3_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>

				<div class="col-12"></div>

				<!-- Code postal -->
				<div class="field col-fixed">
					<label for="abo_code_postal">
						{{ 'common.postal_code_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="abo_code_postal"
						[(ngModel)]="abonne.abo_code_postal"
						[attr.placeholder]="'common.postal_code_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>

				<!-- Code postal -->
				<div class="field col-fixed">
					<label for="abo_ville">
						{{ 'common.city_label' | translate }}
					</label>
					<input
						type="text"
						pInputText
						name="abo_ville"
						[(ngModel)]="abonne.abo_ville"
						[attr.placeholder]="'common.city_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>

				<!-- Pays -->
				<div class="field col-fixed">
					<label for="abo_pays">
						{{ 'common.country_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="country_label"
						[(ngModel)]="abonne.abo_pays"
						[attr.placeholder]="'common.country_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>

				<p-divider class="col-12 mt-4" align="left">
					<b>{{ 'common.contact_label' | translate }}</b>
				</p-divider>

				<!-- Téléphone -->
				<div class="field col-fixed">
					<label for="abo_telephone">
						{{ 'common.phone_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="abo_telephone"
						[(ngModel)]="abonne.abo_telephone"
						[attr.placeholder]="'common.phone_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>

				<!-- Email -->
				<div class="field col-fixed">
					<label for="abo_email">
						{{ 'common.email_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="abo_email"
						[(ngModel)]="abonne.abo_email"
						[attr.placeholder]="'common.email_label' | translate"
						[readOnly]="!editMode"
					/>
				</div>
				<div class="col-12"></div>
			</div>

			<p-divider layout="vertical" class="hidden lg:block p-0"></p-divider>

			<!-- Colonne de droite -->
			<div class="p-fluid grid formgrid p-2 col-6">
				<!-- Abonnement -->
				<p-divider class="col-12" align="left">
					<b>{{ 'abonnes.abonnement_label' | translate }}</b>
				</p-divider>

				<!-- Date d'expiration de l'abonnement -->
				<div class="field col-fixed mb-4">
					<label for="abo_nom_societe">
						{{ 'abonnes.expiration_date_label' | translate }}
					</label>

					<p-calendar
						name="abo_date_expiration"
						[(ngModel)]="abonne.abo_date_expiration"
						dateFormat="dd/mm/yy"
						firstDayOfWeek="1"
						view="date"
						yearRange="2020:2030"
						[showIcon]="true"
						[placeholder]=" 'common.day_month_year_date_placeholder' | translate"
						[disabled]="!editMode"
						required
					></p-calendar>
				</div>

				<div
					class="col-12 md:col-10"
					*ngIf="abonne.abo_nombre_utilisateurs != null && abonne.nombre_utilisateurs_actifs > abonne.abo_nombre_utilisateurs"
				>
					<div

						class="w-full mb-2 border-round border-1 border-yellow-300 p-2 font-bold"
					>
						{{  'abonnes.max_user_number_exceeded' | translate }}
					</div>
				</div>

				<!-- Nombre max d'utilisateurs -->
				<div class="field col-fixed xxl:col-4">
					<label for="abo_nombre_utilisateurs">
						{{ 'abonnes.users_max_number_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						gzInputNumberFormatter
						name="abo_nombre_utilisateurs"
						[(ngModel)]="abonne.abo_nombre_utilisateurs"
						class="text-right"
						[readonly]="!editMode"
					>
				</div>

				<!-- Nombre d'utilisateurs actifs -->
				<div class="field col-fixed xxl:col-4">
					<label>
						{{ 'abonnes.active_user_number_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						gzInputNumberFormatter
						name="nombre_utilisateurs_actifs"
						[(ngModel)]="abonne.nombre_utilisateurs_actifs"
						class="text-right"
						[ngClass]="
							(abonne.abo_nombre_utilisateurs != null && abonne.nombre_utilisateurs_actifs > abonne.abo_nombre_utilisateurs) ?
							'text-color-danger font-bold' : ''
						"
						[readonly]="true"
					>
				</div>

				<div class="col-12"></div>

				<div
					class="col-12 md:col-10"
					*ngIf="abonne.abo_nombre_sites != null && abonne.nombre_sites_actifs > abonne.abo_nombre_sites"
				>
					<div

						class="w-full mb-2 border-round border-1 border-yellow-300 p-2 font-bold"
					>
						{{  'abonnes.max_site_number_exceeded' | translate }}
					</div>
				</div>


				<!-- Nombre max de sites -->
				<div class="field col-fixed xxl:col-4">
					<label for="abo_nombre_sites">
						{{ 'abonnes.sites_max_number_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						gzInputNumberFormatter
						name="abo_nombre_sites"
						[(ngModel)]="abonne.abo_nombre_sites"
						class="text-right"
						[readOnly]="!editMode"
					>
				</div>

				<!-- Nombre de sites actifs -->
				<div class="field col-fixed xxl:col-4">
					<label>
						{{ 'abonnes.active_site_number_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						gzInputNumberFormatter
						name="nombre_sites_actifs"
						[(ngModel)]="abonne.nombre_sites_actifs"
						class="text-right"
						[ngClass]="
							(abonne.abo_nombre_sites != null && abonne.nombre_sites_actifs > abonne.abo_nombre_sites) ?
							'text-color-danger font-bold' : ''
						"
						[readonly]="true"
					>
				</div>
			</div>
		</div>
	</form>

</p-panel>