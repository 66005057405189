import { StateObject, Transition, TransitionService } from '@uirouter/core';

import { AppMenuService } from '@global/app-menu.service';
import { AuthService } from '@app/auth/auth.service';
import { EventManagerService } from '@global/event-manager.service';
import { ParametreService } from '@app/parametre/parametre.service';
import { StateUtilsService } from '@helpers/state-utils.service';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';

// In a guard, we can wait for resolves to complete. Eg.:
// let tokens = transition.getResolveTokens();
// let promises = tokens.map((token: any) => {
// 	console.log('token', token)
// 	// transition.injector().getAsync(token)
// });
// Promise.all(promises).then((values: any) => console.log("Resolved values: " + values));

// hook to force the fetching of the application params
export function parametresGuardHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => {
			return state.data && state.data.parametresGuard;
		},
	};
	const fetchParams = (transition: Transition) => {
		const paramService: ParametreService = transition.injector().get(ParametreService);
		const stateUtilsService: StateUtilsService = transition.injector().get(StateUtilsService);
		const stateService = transition.router.stateService;
		if (!paramService.serverParams) {
			return stateUtilsService.successOrRedirectHook(paramService.getParametres());
		}
		return true;
	};
	transitionService.onBefore(criteria, fetchParams, { priority: 1000 });
}

/**
 * This hook redirects to auth.login when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
export function requiresAuthHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'requiresAuth' property
	const criteria = {
		to: (state: StateObject) => {
			return state.data && state.data.requiresAuth;
		},
	};
	const redirectToLogin = (transition: Transition) => {
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		if (!authService.hasToken()) {
			console.log('No token', 'redirect to login');
			return stateService.target('auth.login', undefined, { location: false });
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToLogin, { priority: 1000 });
}

/**
 * This hook redirects to auth.challenge_2fa when both:
 * - The challenge is required
 * - The challenge is not yet passed
 */
export function auth2faPassed(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'requiresAuth' property
	const criteria = {
		to: (state: StateObject) => {
			return state.data && state.data.auth2faPassed;
		},
	};
	const redirectToChallenge2fa = (transition: Transition) => {
		// console.log('auth2faPassed hook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		const challenge_2fa_required = authService.extractCurrentJWTVAlue('challenge_2fa_required');
		const challenge_2fa_passed = authService.extractCurrentJWTVAlue('challenge_2fa_passed');
		if (challenge_2fa_required && !challenge_2fa_passed) {
			return stateService.target('auth.challenge_2fa', stateService.params, { reload: true, location: true });
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToChallenge2fa, { priority: 500 });
}

// hook to force the fetching of the current user info before entering the substates
export function meGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'meGuard' property
	const criteria = {
		to: (state: StateObject) => {
			return state.data && state.data.meGuard;
		},
	};
	const currentUser = (transition: Transition) => {
		// console.log('meGuardHook');
		const utilisateurService: UtilisateurService = transition.injector().get(UtilisateurService);
		const stateUtilsService: StateUtilsService = transition.injector().get(StateUtilsService);
		const currentUser = utilisateurService.currentUtilisateurValue;
		if (!!!currentUser) {
			return stateUtilsService.successOrLogout(utilisateurService.getCurrentUtilisateur());
		}
		return true;
	};
	transitionService.onBefore(criteria, currentUser, { priority: 800 });
}

// hook to redirect the user if they must change their password
export function passwordExpiredGuardHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'requiresAuth' property
	const criteria = {
		to: (state: StateObject) => {
			return state.data && state.data.passwordExpiredGuard;
		},
	};
	const redirect = (transition: Transition) => {
		// console.log('passwordExpiredGuardHook');
		return transition.injector().getAsync('me') // wait for this resolve
			.then(
				(me: any) => {
					const utilisateurService: UtilisateurService = transition.injector().get(UtilisateurService);
					const currentUser = utilisateurService.currentUtilisateurValue;
					const stateService = transition.router.stateService;
					if (currentUser.expiration_mot_de_passe != null && currentUser.expiration_mot_de_passe <= 0) {
						console.log('Password expired', 'redirect to password change');
						return stateService.target('root.change_password', undefined, { location: false });
					}
					return true;
				},
			);
	};
	transitionService.onBefore(criteria, redirect, { priority: 500 });
}

// hook to prevent accessing auth.* states when user is already logged in
export function forbiddenWhenAuthHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'forbiddenWhenAuth' property
	const criteria = {
		to: (state: StateObject) => {
			return state.data && state.data.forbiddenWhenAuth;
		},
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('forbiddenWhenAuthHook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		if (authService.hasToken()) {
			return stateService.target('root', undefined, { location: true });
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToPortails, { priority: 11 });
}

// hook to prevent accessing auth.challenge_2fa states when user already has a complete token
export function forbiddenWhen2faPassed(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => {
			return state.data && state.data.forbiddenWhen2faPassed;
		},
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('forbiddenWhen2faPassed hook');
		const authService: AuthService = transition.injector().get(AuthService);
		const stateService = transition.router.stateService;
		const challenge_2fa_required = authService.extractCurrentJWTVAlue('challenge_2fa_required');
		const challenge_2fa_passed = authService.extractCurrentJWTVAlue('challenge_2fa_passed');
		if (!challenge_2fa_required || challenge_2fa_passed) {
			return stateService.target('root', undefined, { location: true });
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToPortails, { priority: 11 });
}

// hook to prevent accessing some states when user password is not expired
export function forbiddenWhenPasswordNotExpiredHook(transitionService: TransitionService) {
	// Matches if the destination state's data property has a truthy 'forbiddenWhenAuth' property
	const criteria = {
		to: (state: StateObject) => {
			return state.data && state.data.forbiddenWhenPasswordNotExpired;
		},
	};
	const redirectToPortails = (transition: Transition) => {
		// console.log('forbiddenWhenPasswordNotExpiredHook');
		const utilisateurService: UtilisateurService = transition.injector().get(UtilisateurService);
		const currentUser = utilisateurService.currentUtilisateurValue;
		const stateService = transition.router.stateService;
		if (currentUser.expiration_mot_de_passe && currentUser.expiration_mot_de_passe > 0) {
			return stateService.target('root', undefined, { location: true });
		}
		return true;
	};
	transitionService.onBefore(criteria, redirectToPortails, { priority: 11 });
}

// send a signal to close all modals when changing state
export function closeModalsHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => {
			return true;
		},
	};
	const closeModals = (transition: Transition) => {
		const eventManager: EventManagerService = transition.injector().get(EventManagerService);
		eventManager.emit('close-modals');
		return true;
	};
	transitionService.onFinish(criteria, closeModals, { priority: 15 });
}

// update app menu active status
export function updateAppMenuActiveStatus(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => {
			return true;
		},
	};
	const updateActiveStatus = (transition: Transition) => {
		const appMenuService: AppMenuService = transition.injector().get(AppMenuService);
		appMenuService.updateAppMenuActiveStatus();
		return true;
	};
	transitionService.onSuccess(criteria, updateActiveStatus, { priority: 15 });
}

// hook to prevent the transition to a state on which the user has not enough rights
export function canAccessHook(transitionService: TransitionService) {
	const criteria = {
		to: (state: StateObject) => state.data && state.data.acces && Array.isArray(state.data.acces)
	};
	const stopTransition = (transition: Transition) => {
		const stateUtilsService: StateUtilsService = transition.injector().get(StateUtilsService);
		const stateService = transition.router.stateService;
		const destinationState = transition.to();
		const access: string[] = destinationState.data.acces;

		return transition.injector().getAsync('me').then((me: Utilisateur) => {
			if (access.includes('admin') && me.uti_administrateur) {
				return true;
			}

			console.log('Cannot access', destinationState.name);
			let parentState = stateUtilsService.getFirstNonAbstractParent(destinationState);
			console.log('Redirect to', parentState);
			return stateService.target(parentState, {}, {location: true});
		});
	};
	transitionService.onBefore(criteria, stopTransition, {priority: 10});
}