import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { Abonne, IPRequise } from './abonne.model';

import { Cache } from '@app/_helpers/cache';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import { clone, convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject, fakeAPIRespnse, uid } from '@helpers/utils';
import { environment } from '@environments/environment';
import { UtilisateurAbonne } from '@app/utilisateur/utilisateur.model';


@Injectable({ providedIn: 'root' })
export class AbonneService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private currentAbonneSubject = new BehaviorSubject<Abonne|null>(null);
	public currentAbonne$ = this.currentAbonneSubject.asObservable();

	cacheAbonnes = new Cache<Abonne[]>();

	constructor(
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private http: HttpClient,
		private stateService: StateService,
	) {

		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.currentAbonneSubject.next(null);
		});

	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	public get currentAbonneValue(): Abonne  | null {
		return this.currentAbonneSubject.value;
	}

	public set currentAbonneValue(value: Abonne  | null) {
		this.currentAbonneSubject.next(value);
	}

	public refreshCurrentAbonneData(): Observable<void> {
		let abo = this.currentAbonneValue;
		if (abo) {
			return this.getAbonne(abo.abo_id)
			.pipe(map(abonne => {
				this.currentAbonneSubject.next(abonne!);
			}));
		}
		return of(undefined);
	}

	public prepareAbonneFromServer(abonne: Abonne) {
		let tmp: Abonne = clone(abonne);
		convertDateFieldsToDate(tmp);
		return tmp;
	}

	public prepareAbonneForServer(abonne: Abonne) {
		let tmp = structuredClone(abonne) as ExtensibleObject;
		convertDateFieldsToString(tmp);

		delete tmp.nombre_utilisateurs_actifs;
		delete tmp.nombre_sites_actifs;
		delete tmp.abonnes;
		delete tmp.familles;
		return tmp;
	}

	public prepareAbonnesFromServer(abonnes: Abonne[]) {
		for (let i = 0; i < abonnes.length ; i++) {
			abonnes[i] = this.prepareAbonneFromServer(abonnes[i]);
		}
		return abonnes;
	}

	public getAbonnes(params: unknown) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/abonnes`, tmpParams).pipe(
			map(({ abonnes, total }) => {
				let liste = this.prepareAbonnesFromServer(abonnes);
				return {abonnes: liste, total: total || 0 as number};
			})
		)
	}

	public getAbonne(abo_id: number) {
		return this.http.get<any>(`${environment.api_url}/abonnes/${abo_id}`,).pipe(
			map(response => this.prepareAbonneFromServer(response))
		)
	}

	public createAbonne(abonne: Abonne){
		let body = this.prepareAbonneForServer(abonne);

		return this.http.post<any>(`${environment.api_url}/abonnes`, body);
	}

	public updateAbonne(abonne: Abonne){
		let body = this.prepareAbonneForServer(abonne);

		return this.http.put<any>(`${environment.api_url}/abonnes/${abonne.abo_id}`, body);
	}

	public linkUserToAbonne(user_id: number, abo_id: number, utiAbonne: UtilisateurAbonne){
		return this.http.post<any>(`${environment.api_url}/abonnes/${abo_id}/utilisateurs/${user_id}`, {
			nac_id: utiAbonne.nac_id,
			utb_gestion_abonnement: utiAbonne.utb_gestion_abonnement
		});
	}

	public unlinkUserFromAbonne(user_id: number, abo_id: number){
		return this.http.delete<any>(`${environment.api_url}/abonnes/${abo_id}/utilisateurs/${user_id}`);
	}

	public editAbonneUser(user_id: number, abo_id: number, utiAbonne: UtilisateurAbonne){
		return this.http.put<any>(`${environment.api_url}/abonnes/${abo_id}/utilisateurs/${user_id}`, {
			nac_id: utiAbonne.nac_id,
			utb_gestion_abonnement: utiAbonne.utb_gestion_abonnement
		});
	}

	public getAbonneLogoUploadURL(abo_id: number) {
		return `${environment.api_url}/abonnes/${abo_id}/logo`
	}

	public deleteAbonne(abo_id: number){
		return this.http.delete<any>(`${environment.api_url}/abonnes/${abo_id}`);
	}

	private prepareIpForServer(ip: IPRequise) {
		let tmp = structuredClone(ip) as ExtensibleObject;
		delete tmp.ipr_date_creation;
		delete tmp.ipr_date_modification;
		return tmp;
	}

	public updateAbonneIps(abo_id: number, ips: IPRequise[]){
		let body = {
			ip_requises: ips.map(ip => this.prepareIpForServer(ip))
		};

		return this.http.put<any>(`${environment.api_url}/abonnes/${abo_id}/ip_requises`, body);
	}

	public exportAbonnes(params: unknown){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/abonnes/export`, tmpParams);
	}
}
