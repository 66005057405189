import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (
			!request.url.startsWith('http')
			&& !request.url.startsWith('ws')
			&& !request.url.startsWith(environment.api_url)
		) {
			const maybeSlash = request.url.startsWith('/')? '' : '/' ;
			request = request.clone({
				url: `${environment.api_url}${maybeSlash}${request.url}`
			});
		}
		return next.handle(request);
	}
}
