<!-- Titre -->
<div class="p-panel-title text-xl font-bold mb-3">
	{{ 'abonnes.abonnement_label' | translate }}
</div>

<p-panel class="no-header-panel">

	<div class="p-fluid grid formgrid">
		<div class="field col-fixed">

			<label>
				{{ 'abonnes.active_user_number_label' | translate }}
			</label>

			<div
				*ngIf="abonne.abo_nombre_utilisateurs != null"
				[ngClass]="(abonne.nombre_utilisateurs_actifs > abonne.abo_nombre_utilisateurs) ? 'text-color-danger font-bold' : ''"
			>
				{{abonne.nombre_utilisateurs_actifs}} / {{abonne.abo_nombre_utilisateurs ?? 0}}
			</div>

			<div *ngIf="abonne.abo_nombre_utilisateurs == null">
				{{abonne.nombre_utilisateurs_actifs}}
			</div>
		</div>

		<div class="field col-fixed">
			<label>
				{{ 'abonnes.active_site_number_label' | translate }}
			</label>

			<div
				*ngIf="abonne.abo_nombre_sites != null"
				[ngClass]="(abonne.nombre_sites_actifs > abonne.abo_nombre_sites) ? 'text-color-danger font-bold' : ''"
			>
				{{abonne.nombre_sites_actifs}} / {{abonne.abo_nombre_sites ?? 0}}
			</div>

			<div *ngIf="abonne.abo_nombre_sites == null">
				{{abonne.nombre_sites_actifs}}
			</div>
		</div>

		<div class="col-12 mt-1"></div>

		<div class="field col-2">
			<label>
				{{ 'abonnes.families_label' | translate }}
			</label>

			<ul class="flex flex-column m-0 pl-4">
				<li *ngFor="let famille of abonne.familles" class="mr-2">
					{{famille.fam_nom}}
				</li>
			</ul>
		</div>
	</div>

</p-panel>
