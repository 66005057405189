<div class="p-0">
	<p-panel>

		<ng-template pTemplate="header">
			<span class="font-bold text-lg">
				{{ 'abonnes.security_page_title' | translate }}
			</span>
		</ng-template>


		<ng-template pTemplate="icons">
			<!-- Bouton éditer -->
			<button
				*ngIf="!editMode && canEdit"
				type="button"
				pButton
				class="label-md-none"
				[label]="'common.edition_button_label' | translate"
				icon="pi pi-pencil"
				(click)="toggleEditMode()"
			></button>

			<!-- Bouton enregistrer -->
			<button
				*ngIf="!!editMode"
				type="button"
				pButton
				class="label-md-none"
				[label]="'common.save_button_label' | translate"
				icon="pi pi-save"
				(click)="submit()"
				[disabled]="!securityForm.valid"
			></button>
		</ng-template>

		<div class="flex flex-column p-2">
			<div class="p-fluid grid formgrid">
				<div class="field">
					<label>
						{{'abonnes.allowed_ips_label' | translate }}
					</label>
					<form #securityForm="ngForm">
						<ip-form
							name="ip_requises"
							[(ngModel)]="ip_requises"
							[readonly]="!editMode"
						></ip-form>
					</form>
				</div>
			</div>
		</div>
	</p-panel>
</div>
