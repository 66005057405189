export const superAdminMenuItems = [
	{
		labelKey: 'menu.abonnes',
		icon: 'pi pi-building',
		state: 'abonnes',
	},

	{
		labelKey: 'menu.users',
		icon: 'pi pi-users',
		state: 'utilisateurs',
	},

	{
		labelKey: 'menu.families',
		icon: 'pi pi-book',
		state: 'familles',
	},

	{
		labelKey: 'menu.sites',
		icon: 'pi pi-map-marker',
		state: 'sites',
	}
];
