import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { MenuModule } from '@app/primeng-overrides/menu';
import { Table, TableModule } from '@app/primeng-overrides/table';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';

import { PLACEHOLDER_NAME } from '@app/_global/constantes-langages';
import { EventManagerService } from '@app/_global/event-manager.service';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { GlobalModule } from '@global/global.module';
import { StorageService } from '@global/storage.service';

import { createDownloadFromHttpResponse, ExtensibleObject, loadTranslations } from '@app/_helpers/utils';
import { environment } from '@environments/environment';

const REQUIRED_TRANSLATIONS = [
	'common.create_action_label',
	'common.export_action_label',
	'users.deletion_confirmation',
	'users.deletion_success',
	'users.export_user_list'
];

@Component({
	selector: 'liste-utilisateurs',
	templateUrl: `./liste-utilisateurs.component.html`
})
export class ListeUtilisateursComponent implements OnInit {

	@ViewChild('tableUtilisateurs', { static: true }) table: Table;
	@Input() me: Utilisateur;
	@Input('selected_abo_id') initialAboId?: number;
	@Input() abonne_courant: Abonne;

	values: Utilisateur[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = environment.default_rows;

	params: { [key: string]: any } = {
		search: null,
		abo_id: undefined,
		uti_actif: true,
		uti_administrateur: undefined
	};

	menuItems: MenuItem[] = [];
	canEdit = false;
	isAdminView = false;
	_translations: ExtensibleObject = {};

	constructor(
		private storageService: StorageService,
		private stateService: StateService,
		private utilisateurService: UtilisateurService,
		private abonneService: AbonneService,
		private translate: TranslateService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
	) {

		loadTranslations(translate, this._translations, REQUIRED_TRANSLATIONS, (isFirstLoad: boolean) => {
			if (!isFirstLoad) {
				this.initializeMenu();
			}
		});
	}

	ngOnInit(): void {
		this.canEdit = this.me.uti_administrateur;
		this.isAdminView = this.me.uti_administrateur;

		this.initializeMenu();
		this.getParamSet();

		if (this.initialAboId != undefined) {
			this.params.abo_id = this.initialAboId;
		}

		if (! this.isAdminView) {
			this.abonneService.currentAbonne$.subscribe(abonne => {
				if (abonne) {
					this.refresh();
				}
			})
		}
	}

	private initializeMenu() {
		if (this.canEdit) {
			this.menuItems = [
				{
					label: this._translations['common.create_action_label'],
					icon: 'pi pi-user-plus',
					command: () => {
						this.stateService.go('.create');
					}
				},
			];
		}

		this.menuItems.push(	{
			label: this._translations['common.export_action_label'],
			icon: 'pi pi-download',
			command: () => {
				this.exportUserList();
			}
		});
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);

			const uiParams: any = Object.assign({}, this.stateService.params, this.params, {
				ars_actif: undefined
			});
			this.stateService.go('.', uiParams, { reload: false, location: 'replace', inherit: false });
		}
	}

	resetSearch() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	formatAbonneListColumn(utilisateur: Utilisateur){
		return utilisateur.abonnes.map((abo: Abonne) => abo.abo_nom_abonne).join(', ');
	}

	loadUtilisateurs(event: LazyLoadEvent) {
		const tmpParams = Object.assign({}, event, this.params);

		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => {
			this.loading = true;
		});

		if (this.isAdminView) {

			if (tmpParams.abo_id == -1) {
				tmpParams.abo_id = 'null';
			}

			this.saveParamSet();

			this.utilisateurService
			.getUtilisateurs(tmpParams)
			.subscribe({
				next: ({ utilisateurs, total }) => {
					this.totalRecords = total;
					this.values = utilisateurs;

					// si on revient sur la liste paginée et que la page en question est vide, on reset
					if (this.values.length === 0 && this.table.first != 0) {
						this.refresh();
					}
				}
			})
			.add(() => {
				this.loading = false;
			});
		}
		else if(this.abonne_courant != undefined) {
			delete tmpParams.abo_id;

			this.saveParamSet();

			this.utilisateurService
			.getUtilisateursOfAbonne(this.abonne_courant.abo_id, tmpParams)
			.subscribe({
				next: ({ utilisateurs, total }) => {
					this.totalRecords = total;
					this.values = utilisateurs;

					// si on revient sur la liste paginée et que la page en question est vide, on reset
					if (this.values.length === 0 && this.table.first != 0) {
						this.refresh();
					}
				}
			})
			.add(() => {
				this.loading = false;
			});
		}
	}

	deleteUtilisateur(utilisateur: Utilisateur) {
		let message = this._translations['users.deletion_confirmation']
			.replace(PLACEHOLDER_NAME, '<b>' + utilisateur.uti_nom + ' ' + utilisateur.uti_prenom + '</b>');

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.utilisateurService.deleteUtilisateur(utilisateur.uti_id).subscribe({
					complete: () => {
						this.eventManager.emit('toast', { severity: 'success', summary: this._translations['users.deletion_success'] });
						this.refresh();
					}
				});
			}
		});
	}

	exportUserList(){

		if (this.isAdminView) {
			this.utilisateurService.exportUtilisateurs(this.params)
			.subscribe(response => {
				createDownloadFromHttpResponse(response);
			});
		}
		else {
			this.utilisateurService.exportUtilisateursOfAbonne(this.abonne_courant.abo_id, this.params)
			.subscribe(response => {
				createDownloadFromHttpResponse(response);
			});
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		FormsModule,
		UIRouterModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		AbonneSelectorModule,
		BadgeModule
	],
	exports: [ListeUtilisateursComponent],
	declarations: [ListeUtilisateursComponent]
})
export class ListeUtilisateursModule { }
