import { Component } from '@angular/core';

import {
    TranslateService,
} from "@codeandweb/ngx-translate";

import { MessageService } from 'primeng/api';
import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { FR_LANG, EN_LANG } from '@global/constantes-langages';

import { PrimeNGConfig } from 'primeng/api';

import { ExtensibleObject, uid } from '@helpers/utils';
import { primengTexts } from '@helpers/primeng-texts';

import * as translationsFR from "../assets/i18n/fr.json";
import * as translationsEN from "../assets/i18n/en.json";


@Component({
	selector: 'app-root',
	template: `
		<p-toast [preventOpenDuplicates]="true"></p-toast>
		<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" styleClass="col-12 md:col-6" appendTo="body"></p-confirmDialog>
		<ui-view></ui-view>
		<p-scrollTop></p-scrollTop>
	`,
	providers: [ MessageService ]
})
export class AppComponent implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	constructor(
		private messageService: MessageService,
		private eventManager: EventManagerService,
		private primeNGConfig: PrimeNGConfig,
		private translateService: TranslateService,
	) {
		this.eventManager.registerEvent('toast', this, (args: any) => {
				this.toast(args);
			}
		);

		this.translateService.addLangs([FR_LANG, EN_LANG]);
		this.translateService.setTranslation(FR_LANG, translationsFR);
		this.translateService.setTranslation(EN_LANG, translationsEN);
		this.translateService.setDefaultLang(FR_LANG);
		this.translateService.use(FR_LANG);
	}

	ngOnInit() {
		let texts: ExtensibleObject = primengTexts;
		this.primeNGConfig.setTranslation(texts[this.translateService.currentLang] ?? primengTexts[FR_LANG]);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('toast', this);
	}

	toast(toast: any) {
		if (toast instanceof Array) {
			toast.forEach(one => this.prepareToast(one));
		}
		else {
			this.prepareToast(toast);
		}
		this.messageService.add(toast);
	}

	prepareToast(toast: any) {
		toast.severity = toast.severity || 'info';
		if (toast.severity == 'error') toast.life = 10000;
	}

}
