import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfirmationService } from 'primeng/api';

import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { Cache } from '@app/_helpers/cache';
import { prepareQueryParams, prepareQueryParamsForDownload } from '@app/_helpers/prepare-query-params';
import { environment } from '@environments/environment';
import { clone, convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject, uid } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class UtilisateurService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	private currentUtilisateurSubject = new BehaviorSubject<any>(null);
	public currentUtilisateur$ = this.currentUtilisateurSubject.asObservable();

	private cacheUtilisateurs = new Cache<Utilisateur[]>;

	public civilites: any[] = [
		{long: 'Monsieur', short: 'M.'},
		{long: 'Madame', short: 'Mme'}
	];

	constructor(
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private http: HttpClient,
		private stateService: StateService,
	) {

		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.currentUtilisateurSubject.next(null);
			}
		);

		this.eventManager.registerEvent('refreshCurrentUser', this, (args: any) => {
				this.getCurrentUtilisateur(true).subscribe(response => {
					if (this.currentUtilisateurValue.expiration_mot_de_passe != null && this.currentUtilisateurValue.expiration_mot_de_passe <= 0) {
						this.stateService.go('root.change_password', undefined, { location: false });
					}
				});
			}
		);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
		this.eventManager.unregisterEvent('refreshCurrentUser', this);
	}

	public getCivilites(format?: string) {
		let temp: any[]= [];
		for (let one of this.civilites) {
			if (format && one.hasOwnProperty(format))
				temp.push({label: one[format], value: one[format]});
			else temp.push(one);
		}
		return temp;
	}

	public prepareUtilisateurFromServer(utilisateur: Utilisateur) {
		let tmp: Utilisateur = clone(utilisateur);
		convertDateFieldsToDate(tmp);
		tmp.label = `${tmp.uti_prenom} ${tmp.uti_nom}`;
		tmp.label_full = `${tmp.label} (${tmp.uti_email})`;
		return tmp;
	}

	public prepareUtilisateursFromServer(utilisateurs: Utilisateur[]) {
		for (let i = 0; i < utilisateurs.length ; i++) {
			utilisateurs[i] = this.prepareUtilisateurFromServer(utilisateurs[i]);
		}
	}

	public prepareUtilisateurForServer(utilisateur: Utilisateur) {
		let tmp = structuredClone(utilisateur) as ExtensibleObject;
		convertDateFieldsToString(tmp);

		delete tmp.abonnes;
		return tmp;
	}

	public get currentUtilisateurValue(): Utilisateur {
		return this.currentUtilisateurSubject.value;
	}

	public getCurrentUtilisateur(refresh: boolean = false) {
		return this.http.get<any>(`${environment.api_url}/utilisateurs/moi`)
		.pipe(
			map(utilisateur => {
				this.currentUtilisateurSubject.next(this.prepareUtilisateurFromServer(utilisateur));
				return utilisateur;
			})
		);
	}

	public getUtilisateurs(params: ExtensibleObject)  {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/utilisateurs`, tmpParams)
		.pipe(
			map(({ utilisateurs, total}) => {
				return {
					utilisateurs: utilisateurs.map((uti: any) => this.prepareUtilisateurFromServer(uti)),
					total: total || 0 as number
				}
			})
		);
	}

	public getUtilisateursOfAbonne(abo_id: number, params: ExtensibleObject)  {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/abonnes/${abo_id}/utilisateurs`, tmpParams)
		.pipe(
			map(({ utilisateurs, total}) => {
				return {
					utilisateurs: utilisateurs.map((uti: any) => this.prepareUtilisateurFromServer(uti)),
					total: total || 0 as number
				}
			})
		);
	}

	public getUtilisateur(uti_id: number)  {
		return this.http.get<any>(`${environment.api_url}/utilisateurs/${uti_id}`)
		.pipe(
			map(utilisateur => {
				return utilisateur;
			})
		);
	}

	public updateCurrentUtilisateur(utilisateur: Utilisateur) {
		let body = this.prepareUtilisateurForServer(utilisateur);
		return this.http.put<any>(`${environment.api_url}/utilisateurs/moi`, body)
		.pipe(
			map(response => {
				this.currentUtilisateurSubject.next(this.prepareUtilisateurFromServer(response));
				return response;
			})
		);
	}

	public createUtilisateur(utilisateur: Utilisateur){
		let body = this.prepareUtilisateurForServer(utilisateur);
		this.cacheUtilisateurs.invalidate();

		return this.http.post<any>(`${environment.api_url}/utilisateurs`, body);
	}

	public updateUtilisateur(utilisateur: Utilisateur){
		let body = this.prepareUtilisateurForServer(utilisateur);
		this.cacheUtilisateurs.invalidate();

		return this.http.put<any>(`${environment.api_url}/utilisateurs/${utilisateur.uti_id}`, body);
	}

	public deleteUtilisateur(uti_id: number){
		return this.http.delete<any>(`${environment.api_url}/utilisateurs/${uti_id}`);
	}

	public acceptRGPD() {
		return this.http.post<any>(`${environment.api_url}/utilisateurs/moi/accord_rgpd`, null);
	}

	public disconnect(utilisateur?: Utilisateur) {
		let question: string = `Souhaitez-vous vraiment déconnecter toutes vos sessions ?`;
		if (utilisateur) {
			question = `Souhaitez-vous vraiment déconnecter toutes less sessions de ${utilisateur.label} ?`;
		}

		return new Observable<any>((sub: Subscriber<any>) => {
			this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: question,
				accept: () => {
					const url: string = `/auth/deconnecter_tout`;
					const payload: any = {
						uti_id: utilisateur? utilisateur.uti_id : this.currentUtilisateurValue.uti_id
					}
					this.http.post<any>(url, payload)
					.subscribe({
						next: () => {
							if (utilisateur) {
								this.eventManager.emit('toast', {severity: 'success', summary: 'Sessions déconnectées'});
							}
							else {
								this.eventManager.emit('logout');
							}
						}
					})
					.add(() => {
						sub.next(true);
						sub.complete();
					})
				},
				reject: () => {
					sub.next(true);
					sub.complete();
				}
			});
		});
	}

	exportUtilisateurs(params: unknown){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/utilisateurs/export`, tmpParams);
	}


	exportUtilisateursOfAbonne(abo_id: number, params: unknown){
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`${environment.api_url}/abonnes/${abo_id}/utilisateurs/export`, tmpParams);
	}
}
