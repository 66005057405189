import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { Abonne } from '@app/abonne/abonne.model';
import { GlobalModule } from '@global/global.module';
import { AbonneService } from '@app/abonne/abonne.service';
import { AppMenuModule } from '@app/_global/app-menu/app-menu';


@Component({
	selector: 'abonne-courant',
	template: `
		<app-menu></app-menu>
		<ui-view name="abonneCourant"></ui-view>
	`
})
export class AbonneCourantComponent implements OnInit {

	@Input() abonne: Abonne;

	constructor(
		private stateService: StateService,
		private abonneService: AbonneService
	) {
	}


	ngOnInit(): void {
	}

}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		GlobalModule,
		AppMenuModule
	],
	exports: [AbonneCourantComponent],
	declarations: [AbonneCourantComponent]
})
export class AbonneCourantModule { }
