import { Ng2StateDeclaration, Transition } from '@uirouter/angular';

import { map } from 'rxjs';

import { AbonneCourantComponent } from '@app/abonne-courant';
import { AbonneComponent } from '@app/abonne/abonne.component';
import { AppComponent } from '@app/app.component';
import { AuthComponent } from '@app/auth/auth.component';
import { ChangePasswordComponent, ChangePasswordConnectedComponent } from '@app/auth/change-password';
import { LoginComponent } from '@app/auth/login';
import { Login2faComponent } from '@app/auth/login-2fa';
import { ResetPasswordComponent } from '@app/auth/reset-password';
import { ListeAbonnesComponent } from '@app/liste-abonnes';
import { ListeFamillesComponent } from '@app/liste-familles';
import { ListeSitesComponent } from '@app/liste-sites';
import { ListeUtilisateursComponent } from '@app/liste-utilisateurs';
import { RootComponent } from '@app/root';
import { SelectionAbonneCourantComponent } from '@app/selection-abonne-courant';
import { UtilisateurComponent } from '@app/utilisateur/utilisateur/utilisateur.component';
import * as resolves from '@global/router.resolves';
import { ServiceUnavailableComponent } from '@global/service-unavailable.component';
import { AppMenuService } from './_global/app-menu.service';
import { abonneMenuItems } from './abonne-menu-items';
import { AbonneCreationComponent } from './abonne/abonne-creation/abonne-creation.component';
import { AbonneFamillesTabComponent } from './abonne/abonne-familles-tab';
import { AbonneInfosTabComponent } from './abonne/abonne-infos-tab/abonne-infos-tab.component';
import { AbonneLogoTabComponent } from './abonne/abonne-logo-tab/abonne-logo-tab.component';
import { AbonneAbonnementComponent } from './abonne/abonne-abonnement/abonne-abonnement.component';
import { AbonneSecurityTabComponent } from './abonne/abonne-security-tab';
import { Abonne } from './abonne/abonne.model';
import { AbonneService } from './abonne/abonne.service';
import { FamilleComponent } from './famille/famille';
import { FamilleService } from './famille/famille.service';
import { gestionnaireAbonneMenuItems } from './gestionnaire-abonnement-menu-items';
import { SiteComponent } from './site/site';
import { SiteService } from './site/site.service';
import { superAdminMenuItems } from './super-admin-menu-items';
import { UtilisateurAbonnesTabComponent } from './utilisateur/utilisateur-abonnes-tab/utilisateur-abonnes-tab.component';
import { UtilisateurAccesTabComponent } from './utilisateur/utilisateur-acces-tab/utilisateur-acces-tab.component';
import { UtilisateurCreationComponent } from './utilisateur/utilisateur-creation/utilisateur-creation.component';
import { UtilisateurGeneralTabComponent } from './utilisateur/utilisateur-general-tab/utilisateur-general-tab.component';
import { Utilisateur } from './utilisateur/utilisateur.model';
import { UtilisateurService } from './utilisateur/utilisateur.service';

export const APP_STATES: Ng2StateDeclaration[] = [
	{
		name: 'app',
		component: AppComponent,
		redirectTo: 'root',
		data: {
			parametresGuard: true,
		},
	},

	{
		parent: 'app',
		name: 'auth',
		url: '/auth',
		redirectTo: 'auth.login',
		component: AuthComponent,
		resolve: [
			resolves.returnToResolve,
		],
	},
	{
		name: 'auth.login',
		url: '/login',
		component: LoginComponent,
		data: { forbiddenWhenAuth: true },
	},
	{
		name: 'auth.reset_password',
		url: '/forgotten-password?{email:string}',
		params: {
			email: { dynamic: true, squash: true },
		},
		component: ResetPasswordComponent,
	},
	{
		name: 'auth.change_password',
		url: '/password-change/:resetPasswordToken',
		component: ChangePasswordComponent,
	},
	{
		name: 'auth.challenge_2fa',
		url: '/2fa',
		data: {
			requiresAuth: true,
			forbiddenWhen2faPassed: true,
		},
		component: Login2faComponent,
	},

	{
		parent: 'app',
		name: 'service_unavailable',
		url: '/error?{error:string}',
		component: ServiceUnavailableComponent,
	},

	{
		parent: 'app',
		name: 'root',
		component: RootComponent,
		redirectTo: 'selection-abonne-courant',
		data: {
			requiresAuth: true,
			auth2faPassed: true,
		},
		resolve: [
			resolves.getMeResolve,
		],
		resolvePolicy: { when: 'EAGER', async: 'WAIT' }, // we want to wait for the resolves to complete before going in the substates

	},
	{
		name: 'root.change_password',
		url: '/password-change',
		// component: ChangePasswordConnectedComponent,
		views: {
			'rootContent@root': {
				component: ChangePasswordConnectedComponent,
			},
		},
		resolve: [
			resolves.returnToResolve,
		],
		data: {
			forbiddenWhenPasswordNotExpired: true,
		},
	},

	// Abonnés

	{
		parent: 'root',
		name: 'selection-abonne-courant',
		url: '/current-subscriber/selection',
		views: {
			'rootContent@root': {
				component: SelectionAbonneCourantComponent,
			},
		},
	},

	{
		parent: 'root',
		name: 'abonne-courant',
		url: '/current-subscriber/{curr_abo_id:int}',
		views: {
			'rootContent@root': {
				component: AbonneCourantComponent,
			},
		},
		resolve: [
			{
				token: 'curr_abo_id',
				deps: [Transition],
				resolveFn: (transition: Transition) => {
					return transition.params().curr_abo_id;
				}
			},
			{
				token: 'abonne_courant',
				deps: [AbonneService, Transition],
				resolveFn: (abonneService: AbonneService, transition: Transition) => {
					const curr_abo_id = transition.params().curr_abo_id;

					return abonneService.getAbonne(curr_abo_id)
					.pipe(map(abonne => {
						abonneService.currentAbonneValue = abonne;
						return abonne;
					})).toPromise();
				}
			},
			{
				token: 'menuItems',
				deps: [AppMenuService, AbonneService, Transition, 'me'],
				resolveFn: (appMenuService: AppMenuService, abonneService: AbonneService, transition: Transition, me: Utilisateur) => {
					let curr_abo_id = transition.params().curr_abo_id;

					abonneService.getAbonne(curr_abo_id)
					.subscribe({
						next: (abonne: Abonne) => {
							if (me.uti_administrateur) {
								appMenuService.setMenuItems(superAdminMenuItems);
								return;
							}

							let utilisateurAbonne = abonne.utilisateurs_abonnes.find(utilisateur => utilisateur.uti_id == me.uti_id);

							if (utilisateurAbonne != undefined && utilisateurAbonne.utb_gestion_abonnement) {
								appMenuService.setMenuItems(gestionnaireAbonneMenuItems);
							} else {
								appMenuService.setMenuItems(abonneMenuItems);
							}
						}
					})

					return true;
				},
			},
		],
	},

	{
		parent: 'abonne-courant',
		name: 'abonnes',
		url: '/subscribers',
		data: {
			acces: ['admin']
		},
		views: {
			'abonneCourant@abonne-courant': {
				component: ListeAbonnesComponent,
			},
		},
	},

	{
		parent: 'abonne-courant',
		name: 'abonnement',
		url: '/subscription',
		views: {
			'abonneCourant@abonne-courant': {
				component: AbonneAbonnementComponent
			}
		}
	},

	{
		name: 'abonnes.create',
		url: '/creation',
		views: {
			'abonneCourant@abonne-courant': {
				component: AbonneCreationComponent,
			},
		},
	},

	{
		name: 'abonnes.single',
		url: '/{abo_id:int}',
		redirectTo: 'abonnes.single.informations',
		views: {
			'abonneCourant@abonne-courant': {
				component: AbonneComponent,
			},
		},
		resolve: [
			{
				token: 'abonne',
				deps: [AbonneService, Transition],
				resolveFn: (abonneService: AbonneService, transition: Transition) => {
					const abo_id = transition.params().abo_id;
					return abonneService.getAbonne(abo_id).toPromise();
				}
			}
		],
	},

	{
		name: 'abonnes.single.informations',
		url: '/informations',
		views: {
			'abonneTabContent@abonnes.single': {
				component: AbonneInfosTabComponent
			}
		}
	},

	{
		name: 'abonnes.single.logo',
		url: '/logo',
		views: {
			'abonneTabContent@abonnes.single': {
				component: AbonneLogoTabComponent
			}
		}
	},

	{
		name: 'abonnes.single.familles',
		url: '/familles',
		views: {
			'abonneTabContent@abonnes.single': {
				component: AbonneFamillesTabComponent
			}
		}
	},


	{
		name: 'abonnes.single.securite',
		url: '/security',
		views: {
			'abonneTabContent@abonnes.single': {
				component: AbonneSecurityTabComponent
			}
		}
	},

	// Utilisateurs

	{
		parent: 'abonne-courant',
		name: 'utilisateurs',
		url: '/users?{selected_abo_id:int}',
		params: {
			'selected_abo_id': { dynamic: true, squash: true },
		},
		views: {
			'abonneCourant@abonne-courant': {
				component: ListeUtilisateursComponent,
			},
		},
		resolve: [
			{
				token: 'selected_abo_id',
				deps: [Transition],
				resolveFn: (transition: Transition) => {
					return transition.params().selected_abo_id;
				}
			},
		]
	},

	{
		name: 'utilisateurs.create',
		url: '/creation',
		views: {
			'abonneCourant@abonne-courant': {
				component: UtilisateurCreationComponent,
			},
		},
	},

	{
		name: 'utilisateurs.single',
		redirectTo: 'utilisateurs.single.general',
		url: '/{uti_id:int}',
		views: {
			'abonneCourant@abonne-courant': {
				component: UtilisateurComponent,
			},
		},
		resolve: [
			{
				token: 'utilisateur',
				deps: [UtilisateurService, Transition],
				resolveFn: (utilisateurService: UtilisateurService, transition: Transition) => {
					const uti_id = transition.params().uti_id;
					return utilisateurService.getUtilisateur(uti_id).toPromise();
				}
			}
		],
	},


	{
		name: 'utilisateurs.single.general',
		url: '/general',
		views: {
			'utilisateurTabContent@utilisateurs.single': {
				component: UtilisateurGeneralTabComponent
			}
		}
	},

	{
		name: 'utilisateurs.single.abonnes',
		url: '/linked-subscribers',
		views: {
			'utilisateurTabContent@utilisateurs.single': {
				component: UtilisateurAbonnesTabComponent
			}
		}
	},

	{
		name: 'utilisateurs.single.acces',
		url: '/access',
		views: {
			'utilisateurTabContent@utilisateurs.single': {
				component: UtilisateurAccesTabComponent
			}
		}
	},

	// Sites

	{
		parent: 'abonne-courant',
		name: 'sites',
		url: '/sites',
		views: {
			'abonneCourant@abonne-courant': {
				component: ListeSitesComponent,
			},
		},
	},

	{
		name: 'sites.single',
		url: '/{sit_id:int}',
		resolve: [
			{
				token: 'site',
				deps: [SiteService, Transition, 'curr_abo_id'],
				resolveFn: (siteService: SiteService, transition: Transition, curr_abo_id: number) => {
					const sit_id = transition.params().sit_id;
					return siteService.getSite(curr_abo_id, sit_id).toPromise();
				}
			}
		],
		views: {
			'abonneCourant@abonne-courant': {
				component: SiteComponent,
			},
		},
	},

	{
		name: 'sites.create',
		url: '/creation',
		views: {
			'abonneCourant@abonne-courant': {
				component: SiteComponent,
			},
		},
	},

	// Familles

	{
		parent: 'abonne-courant',
		name: 'familles',
		url: '/families',
		views: {
			'abonneCourant@abonne-courant': {
				component: ListeFamillesComponent,
			},
		},
	},

	{
		name: 'familles.create',
		url: '/creation',
		views: {
			'abonneCourant@abonne-courant': {
				component: FamilleComponent,
			},
		},
	},

	{
		name: 'familles.single',
		url: '/{fam_id:int}',
		resolve: [
			{
				token: 'famille',
				deps: [FamilleService, Transition],
				resolveFn: (familleService: FamilleService, transition: Transition) => {
					const fam_id = transition.params().fam_id;
					return familleService.getFamille(fam_id).toPromise();
				}
			}
		],
		views: {
			'abonneCourant@abonne-courant': {
				component: FamilleComponent,
			},
		},
	},

];
