<!-- Titre -->
<div class="p-panel-title text-xl font-bold mb-3">
	{{ 'users.user_list_page_title' | translate }}
</div>


<p-toolbar styleClass="mb-4">
	<ng-template pTemplate="left">
		<div class="grid formgrid p-fluid h-full align-items-center">
			<!-- Recherche -->
			<div class="field col-fixed">
				<div class="p-inputgroup ">
					<input
						pInputText
						type="text"
						[(ngModel)]="params.search"
						(onDebounce)="refresh()"

						[attr.placeholder]="'common.search_input_placeholder' | translate"
					/>
					<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || !params.search" (click)="resetSearch()"></button>
				</div>
			</div>

			<!-- Sélecteur d'abonné -->
			<abonne-selector
				*ngIf="isAdminView"
				class="field col-fixed"
				name="abo_id"
				[(ngModel)]="params.abo_id"
				(ngModelChange)="refresh()"
				[placeholder]="'abonnes.abonne_label' | translate"
				[nullOption]="true"
				[noAbonneId]="-1"
			></abonne-selector>

			<!-- Coche Actif/Inactif -->
			<div class="field col-fixed flex flex-column justify-content-end">
				<p-checkbox
					name="uti_actif"
					inputId="uti_actif"
					[(ngModel)]="params.uti_actif"
					[binary]="true"
					[label]="'users.inactive_state_plural' | translate"
					[trueValue]="false"
					[falseValue]="true"
					(ngModelChange)="refresh()"
				></p-checkbox>
			</div>

			<!-- Coche Administrateur -->
			<div *ngIf="isAdminView" class="field col-fixed flex flex-column justify-content-end">
				<p-checkbox
					name="uti_administrateur"
					inputId="uti_administrateur"
					[(ngModel)]="params.uti_administrateur"
					[binary]="true"
					[label]="'common.admin_only_label' | translate"
					[trueValue]="true"
					[falseValue]="null"
					(ngModelChange)="refresh()"
				></p-checkbox>
			</div>
		</div>

	</ng-template>

	<ng-template pTemplate="right">
		<button
			type="button"
			pButton
			icon="pi pi-bars"
			[label]="'common.actions_button_label' | translate"
			class="p-panel-header-icon p-link"
			(click)="menu_list_utilisateurs.toggle($event)"
			*ngIf="menuItems.length"
		></button>
		<p-menu #menu_list_utilisateurs [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
	</ng-template>
</p-toolbar>
<p-table
	#tableUtilisateurs
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="loadUtilisateurs($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{{totalRecords | number}} élément(s)"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="text-center">
				{{ 'users.salutation_label' | translate }}
			</th>

			<th class="text-center" pSortableColumn="uti_nom">
				{{ 'users.last_name_label' | translate }} <p-sortIcon field="uti_nom"></p-sortIcon>
			</th>

			<th class="text-center" pSortableColumn="uti_prenom">
				{{ 'users.first_name_label' | translate }} <p-sortIcon field="uti_prenom"></p-sortIcon>
			</th>

			<th class="text-center" *ngIf="isAdminView">
				{{ 'users.abonnes_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'users.function_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.email_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.fixed_phone_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.cellphone_label' | translate }}
			</th>

			<th *ngIf="isAdminView" class="text-center">
				{{ 'common.admin_label' | translate }}
			</th>

			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">Aucun résultat pour ces critères</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-utilisateur>
		<tr [ngClass]="{'inactive': !utilisateur.uti_actif, 'uti-bloque': utilisateur.uti_bloque}">
			<td>{{utilisateur.uti_civilite}}</td>
			<td>{{utilisateur.uti_nom}}</td>
			<td>{{utilisateur.uti_prenom}}</td>
			<td *ngIf="isAdminView">
				{{formatAbonneListColumn(utilisateur)}}
			</td>
			<td>{{utilisateur.uti_fonction}}</td>
			<td>{{utilisateur.uti_email}}</td>
			<td class="nowrap">{{utilisateur.uti_telephone_fixe}}</td>
			<td class="nowrap">{{utilisateur.uti_telephone_mobile}}</td>
			<td *ngIf="isAdminView" class="nowrap text-center" [ngClass]="{'font-bold': utilisateur.uti_administrateur}">
				{{utilisateur.uti_administrateur ? ('common.yes' | translate) : ('common.no' | translate)}}
			</td>
			<td class="nowrap" *ngIf="!readonly">
				<a
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					uiSref=".single"
					[uiParams]="{uti_id: utilisateur.uti_id}"
				></a>
				<button
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					(click)="deleteUtilisateur(utilisateur)"
					*ngIf="canEdit && utilisateur.uti_id != me.uti_id"
				></button>
			</td>
		</tr>
	</ng-template>
</p-table>

