<div class="flex flex-row w-full shadow-1">
	<gz-panelMenu
		class="w-full"
		styleClass="flex flex-row gap-2 p-2 w-full p-panelmenu-no-margin"
		[arrowRight]="true"
		[model]="items"
		[disableExpansionStyle]="true"
	>
	</gz-panelMenu>
</div>
