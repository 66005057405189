import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DialogService } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { IPFormModule } from '@app/components/ip-form';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';
import { AbonneService } from '../abonne.service';

import { ExtensibleObject, loadTranslations } from '@app/_helpers/utils';

const REQUIRED_TRANSLATION_KEYS = [
	'abonnes.security_params_updated'
]

@Component({
	selector: 'abonnes-security-tab',
	templateUrl: `abonne-security-tab.component.html`
})
export class AbonneSecurityTabComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() abonne: Abonne;

	initialAbonne: Abonne;

	isCreation = false;
	canEdit = false;
	editMode = false;

	ip_requises: any[] = [];

	translations: ExtensibleObject = {};

	constructor(
		private abonneService: AbonneService,
		private stateService: StateService,
		private dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private translateService: TranslateService,
	) {
		loadTranslations(translateService, this.translations, REQUIRED_TRANSLATION_KEYS);
	}


	ngOnInit(): void {
		this.ip_requises = this.abonne.ip_requises ?? [];
		this.canEdit = this.me.uti_administrateur;
		console.log(this.canEdit, this.editMode)
	}


	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	submit(){
		this.abonneService.updateAbonneIps(this.abonne.abo_id, this.ip_requises)
		.subscribe({
			complete: () => {
				this.eventManager.emit('toast', { severity: 'success', summary: this.translations['abonnes.security_params_updated'] });
				this.stateService.go('.', undefined, {reload: '^'});
			}
		})
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		DividerModule,
		CheckboxModule,
		GzTabMenuModule,
		IPFormModule
	],
	exports: [AbonneSecurityTabComponent],
	declarations: [AbonneSecurityTabComponent]
})
export class AbonneSecurityTabModule { }
