<p-panel>

	<ng-template pTemplate="header">
		<div class="flex align-items-center">

			<!-- Titre -->
			<div class="p-panel-title mr-3 ml-1">
				<ng-container *ngIf="isCreation">
					{{ 'users.new_user' | translate }}
				</ng-container>

				<ng-container *ngIf="!isCreation">
					{{ initialUtilisateur.uti_prenom }}
					{{ initialUtilisateur.uti_nom }}
				</ng-container>
			</div>

			<!-- Checkbox actif/inactif -->
			<div class="field-checkbox mb-0" *ngIf="!selfEditing">
				<p-checkbox
					name="uti_actif"
					inputId="uti_actif"
					[(ngModel)]="utilisateur.uti_actif"
					[binary]="true"
					inputId="binary"
					[label]="utilisateur.uti_actif ? ('users.active_state' | translate) : ('users.inactive_state' | translate)"
					[disabled]="!editMode"
				></p-checkbox>
			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="icons">
		<!-- Bouton éditer -->
		<button
			*ngIf="!editMode && canEditUser"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.edition_button_label' | translate"
			icon="pi pi-pencil"
			(click)="toggleEditMode()"
		></button>

		<!-- Bouton enregistrer -->
		<button
			*ngIf="!!editMode"
			type="button"
			pButton
			class="label-md-none"
			[label]="'common.save_button_label' | translate"
			icon="pi pi-save"
			(click)="submit()"
			[disabled]="!utilisateurForm.valid"
		></button>
	</ng-template>


	<div class="flex flex-column">
		<form class="mb-2" #utilisateurForm="ngForm">
			<div class="p-fluid grid formgrid p-2 col-8">

				<!-- Email -->
				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_email">
						{{ 'common.email_label' | translate }}
					</label>
					<input
						type="email"
						pInputText
						name="uti_email"
						#uti_email="ngModel"
						[(ngModel)]="utilisateur.uti_email"
						required
						email="true"
						[ngClass]="{ 'p-invalid': submitted && uti_email.errors }"
						placeholder="email@exemple.com"
						[readonly]="!editMode"
					>
				</div>

				<!-- Civilité -->
				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_civilite">
						{{ 'users.salutation_label' | translate }}
					</label>

					<civilite-selector
						name="uti_civilite"
						#uti_civilite
						[(ngModel)]="utilisateur.uti_civilite"
						required
						[readonly]="!editMode"
					></civilite-selector>
				</div>

				<!-- Nom de famille -->
				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_nom">
						{{ 'users.last_name_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="uti_nom"
						#uti_nom="ngModel"
						[(ngModel)]="utilisateur.uti_nom"
						required
						[ngClass]="{ 'p-invalid': submitted && uti_nom.errors }"
						[readonly]="!editMode"
						[placeholder]="'users.last_name_label' | translate"
					>
				</div>

				<!-- Prénom -->
				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_prenom">
						{{ 'users.first_name_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="uti_prenom"
						#uti_prenom="ngModel"
						[(ngModel)]="utilisateur.uti_prenom"
						required
						[ngClass]="{ 'p-invalid': submitted && uti_prenom.errors }"
						[readonly]="!editMode"
						[placeholder]="'users.first_name_label' | translate"
					>
				</div>

				<!-- Téléphone fixe -->
				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_telephone_fixe">
						{{ 'common.fixed_phone_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="uti_telephone_fixe"
						#uti_telephone_fixe="ngModel"
						[(ngModel)]="utilisateur.uti_telephone_fixe"
						[readonly]="!editMode"
					>
				</div>

				<!-- Téléphone mobile -->
				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_telephone_mobile">
						{{ 'common.cellphone_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="uti_telephone_mobile"
						#uti_telephone_mobile="ngModel"
						[(ngModel)]="utilisateur.uti_telephone_mobile"
						[readonly]="!editMode"
					>
				</div>

				<!-- Fonction -->
				<div class="field col-12 md:col-6 xl:col-4">
					<label for="uti_fonction">
						{{ 'users.function_label' | translate }}
					</label>

					<input
						type="text"
						pInputText
						name="uti_fonction"
						#uti_fonction="ngModel"
						[(ngModel)]="utilisateur.uti_fonction"
						[placeholder]="'users.person_function_label' | translate"
						[readonly]="!editMode"
					>
				</div>

				<!-- Accès -->
				<div class="field col-12 md:col-6 xl:col-4" *ngIf="!selfEditing && utilisateur.uti_id">
					<label for="uti_actif">
						{{ 'users.access_label' | translate }}
					</label>
					<div class="field grid mb-0">
						<p-toggleButton
							name="uti_bloque"
							#uti_bloque="ngModel"
							[(ngModel)]="utilisateur.uti_bloque"
							[onLabel]="'users.blocked_label' | translate"
							onIcon="pi pi-times"
							[offLabel]="'users.authorized_label' | translate"
							offIcon="pi pi-check"
							[styleClass]="'p-toggle-on-danger p-toggle-off-primary'"
							[disabled]="!editMode"
						></p-toggleButton>
					</div>
				</div>

				<!-- Administrateur -->
				<div class="field col-12 md:col-6 xl:col-4" *ngIf="!selfEditing && isAdminView">
					<label for="uti_administrateur">
						{{ 'users.admin_label' | translate }}
					</label>

					<br/>

					<div class="field-checkbox mt-1">
						<p-checkbox
							name="uti_administrateur"
							inputId="uti_administrateur"
							[(ngModel)]="utilisateur.uti_administrateur"
							[binary]="true"
							inputId="binary"
							[disabled]="!editMode"
						></p-checkbox>
					</div>
				</div>
			</div>
		</form>

		<ng-container *ngIf="utilisateur.uti_id && canEditUser">
			<div class="field col-fixed">
				<label>{{ 'auth.password_label' | translate }}</label>
				<div>
					<button
						type="button"
						pButton
						[label]="'auth.password_reinit_label' | translate"
						(click)="resetPassword()"
						[disabled]="loading"
						[loading]="reinitialisationMotDePasseLoading"
					></button>
				</div>
			</div>
		</ng-container>
	</div>
</p-panel>