<div class="font-bold text-xl mb-3 flex flex-row align-items-center">
	<div class="mr-1">
		<logo-display [abonne]="initialAbonne" width="3rem"></logo-display>
	</div>

	<div>
		{{initialAbonne.abo_nom_abonne}}
	</div>
</div>

<gz-tabMenu [model]="tabs"></gz-tabMenu>
<ui-view name="abonneTabContent" class="pt-2"></ui-view>
