<form #ipForm="ngForm">
	<p-table
		[value]="value"
		styleClass="p-datatable-sm p-datatable-gridlines table-matrice max-w-50rem"
		[ngClass]="{'table-matrice-condensed': condensed}"
		[autoLayout]="true"
		responsiveLayout="scroll"
	>
		<ng-template pTemplate="emptymessage" *ngIf="!condensed">
			<tr>
				<td class="text-center" [attr.colspan]="readonly? 2 : 3">Aucune IP définie</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="header" *ngIf="!condensed">
			<tr>
				<th class="text-center col-libelle">
					IP
				</th>

				<th class="text-center">
					Description
				</th>

				<th class="nowrap text-center" *ngIf="!readonly">
					<button
						pButton
						type="button"
						class="p-button-xs"
						title="Ajouter une IP"
						icon="pi pi-plus"
						(click)="add()"
					></button>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
			<tr>

				<td class="">
					<input
						type="text"
						pInputText
						name="ip-{{rowIndex}}"
						[(ngModel)]="item.ipr_ip"
						[pattern]="ipPattern"
						[required]="true"
						placeholder="123.123.123.123/32"
						*ngIf="!readonly"
					>
					<span *ngIf="readonly">
						{{item.ipr_ip}}
					</span>
				</td>

				<td class="">
					<input
						type="text"
						pInputText
						name="description-{{rowIndex}}"
						[(ngModel)]="item.ipr_description"
						[placeholder]="'common.description_label' | translate"
						*ngIf="!readonly"
					>
					<span *ngIf="readonly">
						{{item.ipr_description}}
					</span>
				</td>

				<td class="text-center" *ngIf="!readonly">
					<button
						pButton
						type="button"
						icon="pi pi-trash"
						(click)="delete(rowIndex)"
						class="p-button-rounded p-button-text p-button-danger"
					></button>
				</td>

			</tr>

			<tr *ngIf="rowIndex == value.length -1 && !readonly">
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="col-button-1 text-center">
					<button
						pButton
						type="button"
						class="p-button-xs"
						title="Ajouter une IP"
						icon="pi pi-plus"
						(click)="add()"
					></button>
				</td>
			</tr>

		</ng-template>

	</p-table>
</form>
