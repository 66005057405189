import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { NiveauAccessSelectorModule } from '@app/abonne/niveau-acces-selector';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DialogService } from 'primeng/dynamicdialog';
import { PanelModule } from 'primeng/panel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { ExtensibleObject, loadTranslations } from '@app/_helpers/utils';
import { AbonneLogoDisplayModule } from '@app/abonne/abonne-logo-display/abonne-logo-display';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { AbonneModule } from '@app/abonne/abonne.component';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { AbonneLienDialogComponent } from '@app/utilisateur/abonne-lien-dialog';
import { CiviliteSelectorModule } from '@app/utilisateur/civilite-selector';
import { Utilisateur, UtilisateurAbonne } from '@app/utilisateur/utilisateur.model';
import { TranslateService } from '@codeandweb/ngx-translate';
import { GlobalModule } from '@global/global.module';


const REQUIRED_TRANSLATION_KEYS = [
	'abonnes.unlink_confirmation',
	'abonnes.unlink_success',
	'abonnes.access_level_update_success',
	'abonnes.linking_modal_title'
];

@Component({
	selector: 'utilisateur-abonnes-tab',
	templateUrl: `utilisateur-abonnes-tab.component.html`
})
export class UtilisateurAbonnesTabComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() utilisateur: Utilisateur;

	initialUtilisateur: Utilisateur;

	selfEditing = false;
	isAdminView = false;
	isSubscriptionManagerView = false;
	translations: ExtensibleObject = {};

	// Vue du gestionnaire d'abonnement
	currentAbonne: Abonne;
	currentUtiAbonne: UtilisateurAbonne;

	constructor(
		private stateService: StateService,
		private abonneService: AbonneService,
		private eventManager: EventManagerService,
		private dialogService: DialogService,
		private confirmationService: ConfirmationService,
		private translationService: TranslateService,
	) {
		loadTranslations(translationService, this.translations, REQUIRED_TRANSLATION_KEYS);
		this.currentAbonne = this.abonneService.currentAbonneValue!;
	}

	ngOnInit(): void {
		this.isAdminView = this.me.uti_administrateur;
		let utiAbonne = this.currentAbonne.utilisateurs_abonnes.find(utilisateur => utilisateur.uti_id == this.me.uti_id);
		if (utiAbonne && utiAbonne.utb_gestion_abonnement) {
			this.isSubscriptionManagerView = true;
			this.currentUtiAbonne = utiAbonne;
		}

		this.initialUtilisateur = structuredClone(this.utilisateur);
		this.selfEditing = this.me.uti_id == this.utilisateur.uti_id;
	}

	openAbonneLinkDialog(){
		const ref = this.dialogService.open(AbonneLienDialogComponent, {
			header: this.translations['abonnes.linking_modal_title'],
			width: '35%',
			data: {
				unavailableAbonnes: this.utilisateur.abonnes.map(abo => abo.abo_id),
			}
		});
		ref.onClose.subscribe((result) => {
			if (result === undefined) {
				return;
			}

			let {abo_id, nac_id, utb_gestion_abonnement} = result;

			if (abo_id != undefined) {
				this.abonneService.linkUserToAbonne(this.utilisateur.uti_id, abo_id, {
					nac_id: nac_id,
					utb_gestion_abonnement: utb_gestion_abonnement
				})
				.subscribe({
					complete: () => {
						this.stateService.go('.', undefined, {reload: '^'});
					}
				});
			}
		});
	}

	unlinkAbonne(abonne: Abonne){
		this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: this.translations['abonnes.unlink_confirmation'],
				accept: () => {
					this.abonneService.unlinkUserFromAbonne(this.utilisateur.uti_id, abonne.abo_id)
					.subscribe(
						(response: any) => {
							this.eventManager.emit('toast', {
								severity: 'success',
								summary: this.translations['abonnes.unlink_success'],
							});
							this.stateService.go('.', undefined, {reload: '^'});
						}
					);
				}
		});
	}

	editUtilisateurAbonne(abo_id: number, utiAbonne: UtilisateurAbonne){
		this.abonneService.editAbonneUser(this.utilisateur.uti_id, abo_id, {
			nac_id: utiAbonne.nac_id,
			utb_gestion_abonnement: utiAbonne.utb_gestion_abonnement
		})
		.subscribe({
			complete: () => {
				this.eventManager.emit('toast', {
					severity: 'success',
					summary: this.translations['abonnes.access_level_update_success'],
				});
				this.stateService.go('.', undefined, {reload: '.'});
			}
		});
	}

	canEditAccessLevelForAbonne(abonne: Abonne) {
		// Seul l'administrateur et le gestionaire d'abonnement peuvent changer le niveau d'accès.
		return this.me.uti_administrateur || abonne.utilisateurs_abonnes.find(uti => uti.uti_id == this.me.uti_id && uti.utb_gestion_abonnement);
	}

	canEditGestionAbonnementForAbonne(abonne: Abonne) {
		// Seul l'administrateur peut mettre un utilisateur en gestionnaire d'abonnement.
		return this.me.uti_administrateur;
	}
}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		DropdownModule,
		AbonneSelectorModule,
		AbonneModule,
		CiviliteSelectorModule,
		ToggleButtonModule,
		DividerModule,
		NiveauAccessSelectorModule,
		AbonneLogoDisplayModule
	],
	exports: [UtilisateurAbonnesTabComponent],
	declarations: [UtilisateurAbonnesTabComponent]
})
export class UtilisateurAbonnesTabModule { }
