export const environment = {
	production: true,
	version: 'placeholder_version',
	api_url: '/api',
	logo_placeholder: '/assets/images/logo.png',
	logo_placeholder_small: '/assets/images/logo_small.png',
	logo_placeholder_small_bw: '/assets/images/logo_small_bw.png',
	display_instance_name_on_auth_page: true,
	password_pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})',
	default_rows: 10,
};
