import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { AppTopbarModule } from '@global/app-topbar';
import { GlobalModule } from '@global/global.module';
import { AppMenuModule } from '@global/app-menu/app-menu';


@Component({
	selector: 'root',
	templateUrl: 'root.html'
})
export class RootComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		GlobalModule,
		AppTopbarModule,
		AppMenuModule
	],
	exports: [RootComponent],
	declarations: [RootComponent]
})
export class RootModule { }
