import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { AbonneInfosTabModule } from '@app/abonne/abonne-infos-tab/abonne-infos-tab.component';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';

@Component({
	selector: 'abonne-creation',
	template: `
		<abonne-infos-tab class="block m-3" [me]="me"></abonne-infos-tab>
	`
})
export class AbonneCreationComponent  {

	@Input() me: Utilisateur;

	constructor() {}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		ToolbarModule,
		PanelModule,
		ButtonModule,
		DividerModule,
		CheckboxModule,
		AbonneInfosTabModule
	],
	exports: [AbonneCreationComponent],
	declarations: [AbonneCreationComponent]
})
export class AbonneModule { }
