import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { ButtonModule } from 'primeng/button';

import { Abonne } from '@app/abonne/abonne.model';
import { GlobalModule } from '@global/global.module';
import { AbonneService } from '@app/abonne/abonne.service';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { AbonneLogoDisplayModule } from '@app/abonne/abonne-logo-display/abonne-logo-display';

@Component({
	selector: 'selection-abonne-courant',
	template: `
		<div class="flex flex-column align-items-center w-full">
			<form>

				<div class="field w-full">
					<label class="text-2xl mt-3 mb-3"> {{ 'abonnes.current_abonne_selector' | translate }} </label>

					<div class="flex flex-column align-items-center gap-2 w-full">
						<p-card
							*ngFor="let abonne of choices"
							class="cursor-pointer dimmer-on-hover w-full"
							(click)="selectAbonneCourant(abonne.abo_id)">

							<div class="flex flex-row align-items-center gap-1" [ngStyle]="{height: '30px'}">
								<div>
									<logo-display [abonne]="abonne" width="3rem"></logo-display>
								</div>
								{{abonne.abo_nom_abonne}}
							</div>
						</p-card>
					</div>
				</div>
			</form>
		<div>
	`
})
export class SelectionAbonneCourantComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() choices: Abonne[];

	constructor(
		private stateService: StateService,
		private abonneService: AbonneService,
		private utilisateurService: UtilisateurService,
	) {
	}

	ngOnInit(): void {
		if (this.me.uti_administrateur) {
			// L'administrateur a accès à tous les abonnés.
			this.abonneService.getAbonnes({})
			.subscribe(({ abonnes }) => {
				this.choices = abonnes;
			})
		}
		else {
			this.choices = [...this.me.abonnes];
		}
	}

	selectAbonneCourant(abo_id: number){
		this.stateService.go('abonne-courant', {curr_abo_id: abo_id});
	}

}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		GlobalModule,
		DropdownModule,
		FormsModule,
		ButtonModule,
		AbonneLogoDisplayModule
	],
	exports: [SelectionAbonneCourantComponent],
	declarations: [SelectionAbonneCourantComponent]
})
export class SelectionAbonneCourantModule { }
