<p-panel>

	<ng-template pTemplate="header">
		<span class="font-bold text-lg">
			{{ 'users.access_tab_header' | translate }}
		</span>
	</ng-template>

	<div>
		<div *ngIf="isAdminView" class="text-xl flex flex-row align-items-center mb-4">
			<span>{{currentAbonne.abo_nom_abonne}}</span>
			<div class="col-4">
				<a uiSref="abonnes.single" [uiParams]="{abo_id: currentAbonne.abo_id}">
					<button
						type="button"
						pButton
						icon="pi pi-eye"
					></button>
				</a>
			</div>
		</div>


		<form class="p-fluid formgrid grid">

			<div class="field col-2">
				<label>
					{{ 'abonnes.access_level_label' | translate }}
				</label>

				<niveau-access-selector
					name="nac_id"
					[(ngModel)]="currentUtiAbonne.nac_id"
					(ngModelChange)="editUtilisateurAbonne(currentAbonne.abo_id, currentUtiAbonne)"
					[placeholder]="'abonnes.access_level_label' | translate"
					[readonly]="!canEditAccessLevelForAbonne(currentAbonne)"
				>
				</niveau-access-selector>
			</div>

			<div class="field col-6">
				<label>
					{{ 'abonnes.subscription_management_right_label' | translate }}
				</label>

				<br/>

				<p-checkbox
					class="mt-2"
					name="utb_gestion_abonnement"
					inputId="utb_gestion_abonnement"
					[(ngModel)]="currentUtiAbonne.utb_gestion_abonnement"
					(ngModelChange)="editUtilisateurAbonne(currentAbonne.abo_id, currentUtiAbonne)"
					[disabled]="!canEditGestionAbonnementForAbonne(currentAbonne)"
					[binary]="true"
				></p-checkbox>
			</div>
		</form>

		<p-divider class="col-12 mt-4" align="left">
			<b>{{ 'common.sites_label' | translate }}</b>
		</p-divider>

		<!-- Liste des sites liés -->
		<form #sitesForm="ngForm" *ngIf="!selfEditing">
			<div class="flex flex-row flex-wrap">

				<p-card class="col-6 lg:col-4 xl:col-3" *ngFor="let site of currentAbonne.sites">

					<!-- Gestion de l'accès -->
					<div class="site-card-content" *ngIf="getUtiSiteForUtilisateursIfExists(site) as utiSite; else noAccessToSite">
						<div class="flex flex-row align-items-center">
							<div class="text-lg mr-2">
								{{site.sit_libelle}}
							</div>

							<button
								type="button"
								pButton
								icon="pi pi-times-circle"
								class="p-button-rounded p-button-text p-button-danger"
								(click)="unlinkUtilisateurFromSite(site)"
							></button>
						</div>

						<form class="p-fluid formgrid grid mt-3" *ngIf="canEditAccessLevelOfSites">
							<div class="field col-fixed">
								<label>
									{{ 'abonnes.access_level_label' | translate }}
								</label>

								<niveau-access-selector
									name="nac_id"
									[(ngModel)]="utiSite.nac_id"
									(ngModelChange)="editUtilisateurSite(currentAbonne.abo_id, site.sit_id, utiSite)"
									[placeholder]="'abonnes.access_level_label' | translate"
								>
								</niveau-access-selector>
							</div>
						</form>
					</div>

					<!-- Aucun accès donné -->
					<ng-template #noAccessToSite>

						<div class="site-card-content">
							<div class="flex flex-row mb-2">
								<div class="text-lg mr-2">
									{{site.sit_libelle}}
								</div>

								<div class="font-bold">
									{{ 'sites.no_access' | translate }}
								</div>
							</div>

							<button
								type="button"
								pButton
								icon="pi pi-link"
								(click)="linkUtilisateurToSite(site)"
								[label]="'sites.add_to_site_button' | translate"
							></button>
						</div>
					</ng-template>
				</p-card>
			</div>
		</form>
	</div>

</p-panel>