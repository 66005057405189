import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { provideHttpClient } from '@angular/common/http';

import {
	provideTranslateService,
	TranslateModule,
} from "@codeandweb/ngx-translate";

import { UIRouterModule, UIView } from '@uirouter/angular';

import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';

import { GlobalModule } from '@global/global.module';
import { routerConfigFn } from '@global/router.config';

import { ApiErrorInterceptor } from '@helpers/api-error.interceptor';
import { ApiJwtInterceptor } from '@helpers/api-jwt.interceptor';
import { ApiPrefixInterceptor } from '@helpers/api-prefix.interceptor';
import { ApiResponseTypeToBlobInterceptor } from '@helpers/api-response-type-to-blob.interceptor';

import { APP_STATES } from '@app/app.states';
import { AppComponent } from '@app/app.component';


import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		GlobalModule,
		UIRouterModule.forRoot({
			states: APP_STATES,
			useHash: false,
			initial: { state: 'app' },
			otherwise: { state: 'app', params: {}, options: { reload: true }},
			config: routerConfigFn,
		}),
		TranslateModule.forRoot({
            defaultLanguage: 'fr-FR',
        })
	],
	providers: [
		provideHttpClient(withInterceptorsFromDi()),
		{ provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiJwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiResponseTypeToBlobInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'fr-FR'},
		ConfirmationService, DialogService,
		// provideZoneChangeDetection({ eventCoalescing: true }),
		provideTranslateService({
		 	defaultLanguage: 'fr-FR'
		})
	],
	bootstrap: [UIView]
})
export class AppModule { }
