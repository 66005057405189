import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { GlobalModule } from '@global/global.module';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { ParametreService } from '@app/parametre/parametre.service';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

import { uid } from '@helpers/utils';

@Component({
	selector: 'rgpd-dialog',
	templateUrl: './rgpd-dialog.html'
})
export class RGPDDialogComponent implements OnInit, IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	accord: boolean;
	instanceName: string;

	loading: boolean = false;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private parametreService: ParametreService,
		private utilisateurService: UtilisateurService,

	) {

 	}

	ngOnInit(): void {
		this.instanceName = this.parametreService.getParam('instance_name');
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		this.loading = true;
		this.utilisateurService.acceptRGPD()
		.subscribe({
			next: (response: any) => {
				this.dialogRef.close();
			}
		})
		.add(() => { this.loading = false; });
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GlobalModule,
		ButtonModule,
		CheckboxModule,
	],
	exports: [RGPDDialogComponent],
	declarations: [RGPDDialogComponent]
})
export class RGPDDialogModule { }

