import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';

import { map } from 'rxjs';

import { ConfirmationService } from 'primeng/api';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { Site } from './site.model';
import { UtilisateurSite } from '@app/utilisateur/utilisateur.model';

import { prepareQueryParams } from '@app/_helpers/prepare-query-params';
import { clone, convertDateFieldsToDate, convertDateFieldsToString, ExtensibleObject, fakeAPIRespnse, uid } from '@helpers/utils';
import { environment } from '@environments/environment';


@Injectable({ providedIn: 'root' })
export class SiteService implements IEventListener {

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	constructor(
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private http: HttpClient,
		private stateService: StateService,
	) {

	}

	ngOnDestroy(): void {
	}

	public prepareSiteFromServer(site: Site) {
		let tmp: Site = clone(site);
		convertDateFieldsToDate(tmp);
		return tmp;
	}

	public prepareSiteForServer(site: Site) {
		let tmp = structuredClone(site) as ExtensibleObject;
		convertDateFieldsToString(tmp);
		return tmp;
	}

	public prepareSitesFromServer(sites: Site[]) {
		for (let i = 0; i < sites.length ; i++) {
			sites[i] = this.prepareSiteFromServer(sites[i]);
		}
		return sites;
	}

	public getSites(abo_id: number, params: unknown = {}) {
		const tmpParams = prepareQueryParams(params);

		return this.http.get<any>(`${environment.api_url}/abonnes/${abo_id}/sites`, tmpParams).pipe(
			map(({ sites, total }) => {
				let liste = this.prepareSitesFromServer(sites);
				return {sites: liste, total: total || 0 as number};
			})
		)
	}

	public getSite(abo_id: number, sit_id: number) {
		return this.http.get<any>(`${environment.api_url}/abonnes/${abo_id}/sites/${sit_id}`).pipe(
			map(response => this.prepareSiteFromServer(response))
		)
	}

	public createSite(abo_id: number, site: Site){
		let body = this.prepareSiteForServer(site);

		return this.http.post<any>(`${environment.api_url}/abonnes/${abo_id}/sites`, body);
	}

	public updateSite(abo_id: number, site: Site){
		let body = this.prepareSiteForServer(site);

		return this.http.put<any>(`${environment.api_url}/abonnes/${abo_id}/sites/${site.sit_id}`, body);
	}

	public deleteSite(abo_id: number, sit_id: number){
		return this.http.delete<any>(`${environment.api_url}/abonnes/${abo_id}/sites/${sit_id}`);
	}


	public linkUserToSite(user_id: number, abo_id: number, sit_id: number, nac_id: number){
		return this.http.post<any>(`${environment.api_url}/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`, {
			nac_id: nac_id,
		});
	}

	public unlinkUserFromSite(user_id: number, abo_id: number, sit_id: number){
		return this.http.delete<any>(`${environment.api_url}/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`);
	}

	public editUtilisateurSite(user_id: number, abo_id: number, sit_id: number, utiSite: UtilisateurSite){
		return this.http.put<any>(`${environment.api_url}/abonnes/${abo_id}/sites/${sit_id}/utilisateurs/${user_id}`, {
			nac_id: utiSite.nac_id,
		});
	}

}
