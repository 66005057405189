<!-- Titre -->
<div class="p-panel-title text-xl font-bold mb-3">
	{{ 'sites.site_list_page_title' | translate }}
</div>

<p-toolbar styleClass="mb-4">
	<ng-template pTemplate="left">
		<!-- Recherche -->
		<div class="p-inputgroup mr-2">
			<input
				pInputText
				type="text"
				[(ngModel)]="params.search"
				(onDebounce)="refresh()"
				[attr.placeholder]="'common.search_input_placeholder' | translate"
			/>
			<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || !params.search" (click)="resetSearch()"></button>
		</div>

		<!-- Coche Actif/Inactif -->
		<div class="col-fixed flex flex-column justify-content-end">
			<p-checkbox
				name="sit_actif"
				inputId="sit_actif"
				[(ngModel)]="params.sit_actif"
				[binary]="true"
				[label]="'sites.inactive_state_plural' | translate"
				[trueValue]="false"
				[falseValue]="true"
				(ngModelChange)="refresh()"
			></p-checkbox>
		</div>
	</ng-template>

	<ng-template pTemplate="right">
		<button
			type="button"
			pButton
			icon="pi pi-bars"
			[label]="'common.actions_button_label' | translate"
			class="p-panel-header-icon p-link"
			(click)="menu_list_sites.toggle($event)"
			*ngIf="menuItems.length"
		></button>
		<p-menu #menu_list_sites [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
	</ng-template>
</p-toolbar>
<p-table
	#tableSites
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="loadSites($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{{totalRecords | number}} élément(s)"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
>
	<!-- Header -->
	<ng-template pTemplate="header">
		<tr>
			<th class="text-center" pSortableColumn="sit_libelle">
				{{ 'common.label_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.company_name_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.country_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.city_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.postal_code_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'common.email_label' | translate }}
			</th>
			<th class="text-center">
				{{ 'common.phone_label' | translate }}
			</th>

			<th class="text-center">
				{{ 'sites.diagnostic_date_label' | translate }}
			</th>

			<th></th>
		</tr>
	</ng-template>

	<!-- Message table vide -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">
				{{ 'common.no_table_results' | translate }}
			</td>
		</tr>
	</ng-template>


	<ng-template pTemplate="body" let-site>
		<tr [ngClass]="{'inactive': !site.sit_actif}">
			<td>{{site.sit_libelle}}</td>
			<td>{{site.sit_nom_societe}}</td>
			<td>{{site.sit_pays}}</td>
			<td>{{site.sit_ville}}</td>
			<td>{{site.sit_code_postal}}</td>
			<td>{{site.sit_email}}</td>
			<td>{{site.sit_telephone}}</td>
			<td class="nowrap text-center">{{site.sit_date_diagnostic | date:'shortDate'}}</td>

			<td class="nowrap" *ngIf="!readonly">
				<a
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					uiSref=".single"
					[uiParams]="{sit_id: site.sit_id}"
				></a>
				<button
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					(click)="deleteSite(site)"
					*ngIf="canEdit"
				></button>
			</td>
		</tr>
	</ng-template>

</p-table>

