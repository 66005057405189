<div class="p-0">
	<p-panel>
		<ng-template pTemplate="header">
			<div class="flex align-items-center">

				<!-- Titre -->
				<div class="p-panel-title mr-3 ml-1">
					<ng-container *ngIf="isCreation">
						{{ 'families.new_family' | translate }}
					</ng-container>

					<ng-container *ngIf="!isCreation">
						{{ initialFamille.fam_nom }}
					</ng-container>
				</div>

				<!-- Checkbox actif/inactif -->
				<div class="field-checkbox mb-0">
					<p-checkbox
						name="fam_actif"
						inputId="fam_actif"
						[(ngModel)]="famille.fam_actif"
						[binary]="true"
						inputId="binary"
						[label]="famille.fam_actif ? ('families.active_state' | translate) : ('families.inactive_state' | translate)"
						[disabled]="!editMode"
					></p-checkbox>
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="icons">
			<!-- Bouton éditer -->
			<button
				*ngIf="!editMode && canEditFamille"
				type="button"
				pButton
				class="label-md-none"
				[label]="'common.edition_button_label' | translate"
				icon="pi pi-pencil"
				(click)="toggleEditMode()"
			></button>

			<!-- Bouton enregistrer -->
			<button
				*ngIf="!!editMode"
				type="button"
				pButton
				class="label-md-none"
				[label]="'common.save_button_label' | translate"
				icon="pi pi-save"
				(click)="submit()"
				[disabled]="!siteForm.valid"
			></button>
		</ng-template>


		<div class="flex flex-column">
			<form class="mb-2" #siteForm="ngForm">
				<div class="p-fluid grid formgrid p-2">

					<!-- Nom -->
					<div class="field col-8 sm:col-4 xl:col-2">
						<label for="fam_nom">
							{{ 'common.name_label' | translate }}
						</label>
						<input
							type="text"
							pInputText
							name="fam_nom"
							[(ngModel)]="famille.fam_nom"
							[attr.placeholder]="'common.name_label' | translate"
							[readOnly]="!editMode"
							required
						/>
					</div>

					<div class="col-12"></div>

					<!-- Description -->

					<div class="field col-12 sm:col-10 md:col-8 lg:col-6 xl:col-4">
						<label for="fam_description">
							{{ 'common.description_label' | translate }}

						</label>
						<textarea
							pInputTextarea
							name="fam_description"
							[(ngModel)]="famille.fam_description"
							[autoResize]="true"
							[readOnly]="!editMode"
						></textarea>
					</div>

				</div>
			</form>

		</div>
	</p-panel>
</div>
