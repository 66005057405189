<p-panel class="no-header-panel">
	<ng-template pTemplate="icons">
		<!-- <button
			type="button"
			pButton
			label="Supprimer"
			icon="pi pi-trash"
			class="p-button-danger label-sm-none mr-2"
			(click)="deleteLogo()"
			*ngIf="entity[attr] && canEdit"
		></button> -->
		<!-- <a
			[attr.href]="getUrlOriginal()"
			target="_blank"
			download="{{entity[attr]}}"
			*ngIf="entity[attr]"
		>
			<button
				type="button"
				pButton
				label="Télécharger"
				icon="pi pi-download"
				class="label-sm-none"
			></button>
		</a> -->
	</ng-template>

	<div [ngClass]="{'grid': canEdit}">
		<div [ngClass]="canEdit? 'col-12 md:col-3 lg:col-2': 'text-center'" *ngIf="abonne.abo_fichier_logo">
			<div class="logo-container logo-container-preview">
				<logo-display [abonne]="abonne" size="small"></logo-display>
			</div>
		</div>

		<div class="col-12 md:col-9 lg:col-10" *ngIf="canEdit">
			<p-fileUpload
				#uploader
				name="file"
				[chooseLabel]="'common.file_upload_file'| translate"
				[url]="urlUpload"
				[accept]="acceptedMimeTypes"
				[fileLimit]="1"
				[maxFileSize]="maxFileSize"
				[showUploadButton]="false"
				[showCancelButton]="false"
				[disabled]="uploader.uploading"
				[invalidFileLimitMessageDetail]="invalidFileLimitMessageDetail"
				[invalidFileLimitMessageSummary]="invalidFileLimitMessageSummary"
				[invalidFileSizeMessageDetail]="invalidFileSizeMessageDetail"
				[invalidFileSizeMessageSummary]="invalidFileSizeMessageSummary"
				[invalidFileTypeMessageDetail]="invalidFileTypeMessageDetail"
				[invalidFileTypeMessageSummary]="invalidFileTypeMessageSummary"
				(onUpload)="onUpload($event)"
				(onError)="onError($event)"
			>

				<ng-template pTemplate="toolbar">
					<button
						type="button"
						pButton
						icon="pi pi-upload"
						[label]="'common.file_upload_send' | translate"
						[disabled]="!uploader.files.length || uploader.uploading"
						(click)="upload()"
					></button>
					<button
						type="button"
						pButton
						icon="pi pi-ban"
						[label]="'common.file_upload_cancel' | translate"
						[disabled]="!uploader.uploading"
						(click)="abort()"
					></button>
				</ng-template>

				<ng-template pTemplate="content" let-files>
					<div class="text-center clickable" *ngIf="!uploader.files.length" (click)="uploader.choose()">
						<div>
							<i class="pi pi-icon pi-file"></i>
							{{'abonnes.logo_upload_put_explanation' | translate}}
						</div>
						<div><small> {{'abonnes.logo_upload_accepted_files_label' | translate}} : {{acceptedExtensions}}</small></div>
						<div><small> {{'abonnes.logo_upload_max_size' | translate}} : {{maxFileSize|formatSize}}</small></div>
					</div>
				</ng-template>

				<ng-template let-file let-index="index" pTemplate="file">
					<div class="flex align-items-center justify-content-stretch">
						<div class="p-fileupload-thumb">
							<img [src]="file.objectURL" />
						</div>
						<div class="p-fileupload-file-info">
							<div>
								<div class="overflow-hidden white-space-nowrap text-overflow-ellipsis">{{file.name}}</div>
								<div><small>{{ file.size | formatSize }}</small></div>
							</div>
						</div>
						<div class="p-fileupload-button ml-auto">
							<button type="button" pButton icon="pi pi-times" [disabled]="uploader.uploading" (click)="remove(file)"></button>
						</div>
					</div>
				</ng-template>

			</p-fileUpload>
		</div>
	</div>

</p-panel>
