<form #profilForm="ngForm">
	<fieldset [disabled]="loading || disconnecting">
		<div class="p-fluid formgrid grid">
			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_email">Email</label>
				<input
					name="uti_email"
					#uti_email
					[(ngModel)]="utilisateur.uti_email"
					type="email"
					required
					email="true"
					pInputText
					[ngClass]="{ 'p-invalid': submitted && uti_email.errors }"
					placeholder="email@exemple.com"
				>
				<div *ngIf="submitted && uti_email.errors" class="invalid-feedback p-invalid">
					<small *ngIf="uti_email.errors.required" >Email requis</small>
					<small *ngIf="uti_email.errors.email">Adresse email invalide</small>
				</div>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_civilite">Civilité</label>
				<civilite-selector
					name="uti_civilite"
					#uti_civilite
					[(ngModel)]="utilisateur.uti_civilite"
					required
				></civilite-selector>
				<div *ngIf="submitted && uti_civilite.errors" class="invalid-feedback p-invalid">
					<small *ngIf="uti_civilite.errors.required" >Civilité requise</small>
				</div>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_nom">Nom</label>
				<input
					name="uti_nom"
					#uti_nom
					[(ngModel)]="utilisateur.uti_nom"
					required
					type="text"
					pInputText
					[ngClass]="{ 'p-invalid': submitted && uti_nom.errors }"
				>
				<div *ngIf="submitted && uti_nom.errors" class="invalid-feedback p-invalid">
					<small *ngIf="uti_nom.errors.required" >Nom requis</small>
				</div>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_prenom">Prénom</label>
				<input
					type="text"
					pInputText
					name="uti_prenom"
					#uti_prenom
					[(ngModel)]="utilisateur.uti_prenom"
					required
					[ngClass]="{ 'p-invalid': submitted && uti_prenom.errors }"
				>
				<div *ngIf="submitted && uti_prenom.errors" class="invalid-feedback p-invalid">
					<small *ngIf="uti_prenom.errors.required" >Prénom requis</small>
				</div>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_telephone_fixe">Téléphone fixe</label>
				<input
					type="text"
					pInputText
					name="uti_telephone_fixe"
					#uti_telephone_fixe
					[(ngModel)]="utilisateur.uti_telephone_fixe"
				>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_telephone_mobile">Téléphone mobile</label>
				<input
					type="text"
					pInputText
					name="uti_telephone_mobile"
					#uti_telephone_mobile
					[(ngModel)]="utilisateur.uti_telephone_mobile"
				>
			</div>

			<div class="field col-12 md:col-6 xl:col-4">
				<label for="uti_fonction">Fonction</label>
				<input
					type="text"
					pInputText
					name="uti_fonction"
					#uti_fonction
					[(ngModel)]="utilisateur.uti_fonction"
					placeholder="Votre fonction"
				>
			</div>

			<div class="field col-fixed">
				<label>Déconnecter toutes mes sessions</label>
				<div>
					<button
						type="button"
						pButton
						label="Déconnecter"
						(click)="disconnect()"
						[disabled]="loading"
						[loading]="disconnecting"
					></button>
				</div>
			</div>

		</div>
		<div class="text-center">
			<button
				type="button"
				pButton
				class="p-button-outlined mr-2"
				label="Annuler"
				[disabled]="loading || disconnecting"
				(click)="cancel()"
			></button>
			<button
				type="button"
				pButton
				[label]="'common.save_button_label' | translate"
				[loading]="loading"
				[disabled]="!profilForm.form.valid || disconnecting"
				(click)="submit()"
			></button>
		</div>
	</fieldset>
</form>
