<!-- Logo et menu -->
<div class="flex align-items-center">
	<div class="logo mr-2 hidden sm:block" title="{{version}}">
		<img src="/assets/images/logo.png" class="logo-small">
	</div>

	<div *ngIf="currentAbonne" class="border-round p-2 bg-white ml-2 shadow-2 cursor-pointer dimmer-on-hover flex flex-row align-items-center"
		(click)="goToAbonneSelection()"
	>
		<div class="w-max mr-1 h-full">
			<logo-display [abonne]="currentAbonne" height="1.5rem"></logo-display>
		</div>

		{{currentAbonne.abo_nom_abonne}}
		<i class="pi pi-sync ml-1"></i>
	</div>
</div>

<!-- Profil utilisateur -->
<div class="flex align-items-center">
	<button
		type="button"
		pButton
		class="p-button-rounded p-button-text user-menu-opener"
		icon="pi pi-user"
		(click)="currentUtilisateurMenu.toggle($event)"
		[label]="
			currentUtilisateur?.uti_administrateur ?
			('users.admin_role_name' | translate)
			: isSubscriptionManager ? ('users.subscription_manager_role_name' | translate)
			: ''
		"
	></button>
	<p-menu styleClass="w-max" #currentUtilisateurMenu [popup]="true" [model]="currentUtilisateurMenuItems" appendTo="body"></p-menu>
</div>

