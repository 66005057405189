<div class="login-body">
	<div class="login-wrapper">
		<div class="login-panel flex flex-column justify-content-evenly">
			<div class="flex-shrink-0">
				<div class="text-center">
					<!-- <div><img class="logo mb-5" src="/assets/images/logo.png"></div> -->
					<h1 *ngIf="instanceName && display_instance_name_on_auth_page" class="mt-0">
						{{instanceName}}
					</h1>
				</div>

				<ui-view class="login-form mb-5"></ui-view>
			</div>
			<div class="flex-shrink-0" *ngIf="mailtoHelp">
				<p class="text-center">
					{{ 'auth.need_help?' | translate }}
					<a href="mailto:{{mailtoHelp}}">
						{{ 'auth.click_here' | translate }}
					</a>
						{{ 'auth.for_contacting_us' | translate }}
					</p>
			</div>

			<div class="flex-shrink-0 text-center" *ngIf="displayAppLogo">
				<img class="logo-sm mb-2" src="/assets/images/logo.png">
			</div>

		</div>
		<ng-container *ngIf="frontPage">
			<div class="landing" [innerHTML]="frontPage"></div>
		</ng-container>
	</div>

</div>
