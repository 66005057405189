import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { Abonne } from '@app/abonne/abonne.model';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';
import { AbonneLogoDisplayModule } from './abonne-logo-display/abonne-logo-display';

import { ExtensibleObject, loadTranslations } from '@app/_helpers/utils';


const REQUIRED_TRANSLATION_KEYS = [
	'abonnes.infos_tab_name',
	'abonnes.logo_tab_name',
	'abonnes.families_tab_name',
	'abonnes.security_tab_name',
];

@Component({
	selector: 'abonne',
	templateUrl: `abonne.component.html`
})
export class AbonneComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() abonne: Abonne;

	tabs: any[];

	initialAbonne: Abonne;
	translations: ExtensibleObject = {};

	constructor(
		private stateService: StateService,
		private translationService: TranslateService,
	) {
		loadTranslations(translationService, this.translations, REQUIRED_TRANSLATION_KEYS, () => {
			this.tabs = [
				{
					label: this.translations['abonnes.infos_tab_name'],
					state: '.informations',
				},

				{
					label: this.translations['abonnes.logo_tab_name'],
					state: '.logo',
				},

				{
					label: this.translations['abonnes.families_tab_name'],
					state: '.familles',
				},

				{
					label: this.translations['abonnes.security_tab_name'],
					state: '.securite',
				},
			];
		});

	}

	ngOnInit(): void {
		this.initialAbonne = structuredClone(this.abonne);
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		CalendarModule,
		DividerModule,
		CheckboxModule,
		LogoUploaderModule,
		GzTabMenuModule,
		AbonneLogoDisplayModule
	],
	exports: [AbonneComponent],
	declarations: [AbonneComponent]
})
export class AbonneModule { }
