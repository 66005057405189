import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { MessageModule } from 'primeng/message';
import { ProgressBarModule } from 'primeng/progressbar';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { AuthService } from '@app/auth/auth.service';
import { GlobalModule } from '@app/_global/global.module';

import { loadTranslation } from '@app/_helpers/utils';

@Component({
	selector: 'reset-password',
	templateUrl: 'reset-password.html'
})
export class ResetPasswordComponent implements OnInit {

	resetPasswordForm: FormGroup;
	submitted = false;
	messageSuccess = '';
	success = false;
	loading = false;
	error = '';

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private stateService: StateService,
		private translateService: TranslateService
	) {

		loadTranslation(translateService, 'auth.login_form_password_reinit_sending_success_summary', translation => {
			this.messageSuccess = translation;
		})
	}

	ngOnInit() {
		let tmpEmail = '';
		if (this.stateService.params && this.stateService.params.email) {
			tmpEmail = this.stateService.params.email;
		}
		this.resetPasswordForm = this.formBuilder.group({
			email: [tmpEmail, [Validators.required, Validators.email]],
		});

	}

	get f() { return this.resetPasswordForm.controls; }

	onSubmit(): void {
		this.loading = true;
		this.submitted = true;
		this.error = '';
		if (this.resetPasswordForm.invalid) return;
		this.authService.reinitialisationMotDePasse(this.f.email.value)
		.subscribe(
			(response: any) => {
				this.success = true;
				this.loading = false;
			},
			(error: any) => {
				this.loading = false;
				if (error.status === 401) {
					this.error = 'Identifiant invalide';
				}
			}
		);
	}
}


@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		FormsModule,
		UIRouterModule,
		ReactiveFormsModule,
		ButtonModule,
		InputTextModule,
		MessageModule,
		ProgressBarModule,
	],
	exports: [ResetPasswordComponent],
	declarations: [ResetPasswordComponent]
})
export class ResetPasswordModule { }
