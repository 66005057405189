import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';


import { StateService, UIRouterModule } from '@uirouter/angular';

import { CalendarModule } from '@app/primeng-overrides/calendar';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';

import { EventManagerService } from '@app/_global/event-manager.service';
import { LogoUploaderModule } from '@app/abonne/abonne-logo-uploader';
import { Abonne } from '@app/abonne/abonne.model';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';

import { ExtensibleObject, loadTranslations } from '@app/_helpers/utils';
import { AbonneService } from '@app/abonne/abonne.service';
import { Famille } from '../famille.model';
import { FamilleService } from '../famille.service';


const REQUIRED_TRANSLATION_KEYS = [
	'families.family_created_success',
	'families.family_updated_success'
];

@Component({
	selector: 'famille',
	templateUrl: `famille.component.html`
})
export class FamilleComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() famille: Famille;
	@Input() abonne_courant: Abonne;

	initialFamille: Famille;
	translations: ExtensibleObject = {};

	submitted = false;
	canEditFamille = false;
	isCreation = false;
	editMode = false;
	loading = false;

	constructor(
		private stateService: StateService,
		private familleService: FamilleService,
		private eventManager: EventManagerService,
		private translationService: TranslateService,
		private abonneService: AbonneService,
	) {
		loadTranslations(translationService, this.translations, REQUIRED_TRANSLATION_KEYS);
	}


	toggleEditMode(status?: boolean) {
		this.editMode = (status !== undefined)? status : !!!this.editMode;
	}

	ngOnInit(): void {
		this.isCreation = this.famille == undefined;
		this.canEditFamille = this.me.uti_administrateur;
		this.initialFamille = structuredClone(this.famille);

		if (this.isCreation) {
			this.editMode = true;
			this.famille = {fam_actif: true} as Famille;
		}
	}

	submit(){
		this.submitted = true;

		if (this.isCreation) {
			this.familleService.createFamille(this.famille)
			.subscribe({
				next: ({ fam_id }) => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translations['families.family_created_success'] });

					this.abonneService.refreshCurrentAbonneData()
					.subscribe((y) => {
						this.stateService.go('^.single', {...this.stateService.params, fam_id: fam_id});
					});
				}
			})
		}
		else {
			this.familleService.updateFamille(this.famille)
			.subscribe({
				next: () => {
					this.eventManager.emit('toast', { severity: 'success', summary: this.translations['families.family_updated_success'] });
					this.abonneService.refreshCurrentAbonneData()
					.subscribe(() => {
						this.stateService.go('.', undefined, {reload: '^'});
					})
				}
			})
		}
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		CalendarModule,
		DividerModule,
		CheckboxModule,
		LogoUploaderModule,
		GzTabMenuModule,
		InputTextareaModule
	],
	exports: [FamilleComponent],
	declarations: [FamilleComponent]
})
export class FamilleModule { }
