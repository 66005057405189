import { Abonne } from "@app/abonne/abonne.model";

export class Utilisateur {
	uti_id: number;
	uti_email: string;
	uti_nom: string;
	uti_prenom: string;
	uti_civilite: string;
	uti_fonction: string | null;
	uti_actif: boolean = true;
	uti_bloque: boolean = false;
	uti_date_accord_rgpd: Date;
	uti_date_creation: Date;
	uti_derniere_connexion: Date;
	uti_essai_connexion: number = 0;
	uti_telephone_fixe: string;
	uti_telephone_mobile: string;
	uti_options?: any;
	expiration_mot_de_passe: number;

	label?: string;
	label_full?: string;

	uti_administrateur: boolean
	abonnes: (Abonne & UtilisateurAbonne)[]

	constructor(uti_email?: string, uti_nom?: string) {
		if (uti_email) this.uti_email = uti_email;
		if (uti_nom) this.uti_nom = uti_nom;
	}
}

export interface UtilisateurAbonne {
	utb_gestion_abonnement: boolean;
	nac_id: number;
}

export interface UtilisateurSite {
	uti_id: number;
	nac_id: number;
}