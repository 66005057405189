import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { NiveauAccessSelectorModule } from '@app/abonne/niveau-acces-selector';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MenuModule } from '@app/primeng-overrides/menu';
import { TableModule } from '@app/primeng-overrides/table';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';

import { AbonneLogoDisplayModule } from '@app/abonne/abonne-logo-display/abonne-logo-display';
import { AbonneSelectorModule } from '@app/abonne/abonne-selector/abonne-selector';
import { AbonneModule } from '@app/abonne/abonne.component';
import { Abonne } from '@app/abonne/abonne.model';
import { GzTabMenuModule } from '@app/components/tabmenu';
import { CiviliteSelectorModule } from '@app/utilisateur/civilite-selector';
import { Utilisateur, UtilisateurAbonne } from '@app/utilisateur/utilisateur.model';
import { TranslateService } from '@codeandweb/ngx-translate';
import { GlobalModule } from '@global/global.module';

import { ExtensibleObject, loadTranslations } from '@app/_helpers/utils';
import { AbonneService } from '@app/abonne/abonne.service';

const REQUIRED_TRANSLATION_KEYS = [
	'users.general_tab_name',
	'users.linked_abonnes_tab_name',
	'users.access_tab_name',
]

@Component({
	selector: 'utilisateur',
	templateUrl: `utilisateur.component.html`
})
export class UtilisateurComponent implements OnInit {

	@Input() me: Utilisateur;
	@Input() utilisateur: Utilisateur;
	initialUtilisateur: Utilisateur;

	tabs: any[];
	translations: ExtensibleObject = {};

	// Vue du gestionnaire d'abonnement
	currentAbonne: Abonne;
	currentUtiAbonne: UtilisateurAbonne;

	constructor(
		private translationService: TranslateService,
		private abonneService: AbonneService,
	) {
		this.currentAbonne = this.abonneService.currentAbonneValue!;
	}

	ngOnInit(): void {
		let isSelfEditing = this.me.uti_id == this.utilisateur.uti_id;

		loadTranslations(this.translationService, this.translations, REQUIRED_TRANSLATION_KEYS, () => {
			this.tabs = [
				{
					label: this.translations['users.general_tab_name'],
					state: '.general',
				},
			];

			if (isSelfEditing) {
				return;
			}

			if (this.me.uti_administrateur) {
				this.tabs.push({
					label: this.translations['users.linked_abonnes_tab_name'],
					state: '.abonnes',
				});
			}

			let amIGestionnaireAbo =
				this.currentAbonne.utilisateurs_abonnes.find(utiAbo => utiAbo.utb_gestion_abonnement && utiAbo.uti_id == this.me.uti_id);

			let isUtilisateurLinkedToCurrentAbo = this.currentAbonne.utilisateurs_abonnes.find(utiAbo => utiAbo.uti_id == this.utilisateur.uti_id);

			if (isUtilisateurLinkedToCurrentAbo && (this.me.uti_administrateur || amIGestionnaireAbo)) {
				this.tabs.push({
					label: this.translations['users.access_tab_name'],
					state: '.acces',
				});
			}
		});

		this.initialUtilisateur = structuredClone(this.utilisateur);
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule,
		PanelModule,
		ButtonModule,
		InputTextModule,
		DropdownModule,
		AbonneSelectorModule,
		AbonneModule,
		CiviliteSelectorModule,
		ToggleButtonModule,
		DividerModule,
		NiveauAccessSelectorModule,
		AbonneLogoDisplayModule,
		GzTabMenuModule
	],
	exports: [UtilisateurComponent],
	declarations: [UtilisateurComponent]
})
export class UtilisateurModule { }
