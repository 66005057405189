<!-- Titre -->
<div class="p-panel-title text-xl font-bold mb-3">
	{{ 'families.family_list_page_title' | translate }}
</div>

<p-toolbar styleClass="mb-4">
	<ng-template pTemplate="left">
		<!-- Recherche -->
		<div class="p-inputgroup mr-2">
			<input
				pInputText
				type="text"
				[(ngModel)]="params.search"
				(onDebounce)="refresh()"
				[attr.placeholder]="'common.search_input_placeholder' | translate"
			/>
			<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || !params.search" (click)="resetSearch()"></button>
		</div>

		<!-- Coche Actif/Inactif -->
		<div class="col-fixed flex flex-column justify-content-end">
			<p-checkbox
				name="fam_actif"
				inputId="fam_actif"
				[(ngModel)]="params.fam_actif"
				[binary]="true"
				[label]="'families.inactive_state_plural' | translate"
				[trueValue]="false"
				[falseValue]="true"
				(ngModelChange)="refresh()"
			></p-checkbox>
		</div>
	</ng-template>

	<ng-template pTemplate="right">
		<button
			type="button"
			pButton
			icon="pi pi-bars"
			[label]="'common.actions_button_label' | translate"
			class="p-panel-header-icon p-link"
			(click)="menu_list_familles.toggle($event)"
			*ngIf="menuItems.length"
		></button>
		<p-menu #menu_list_familles [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
	</ng-template>
</p-toolbar>
<p-table
	#tableFamilles
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="loadFamilles($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{{totalRecords | number}} élément(s)"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
>
	<!-- Header -->
	<ng-template pTemplate="header">
		<tr>
			<th class="text-center" pSortableColumn="fam_nom">
				{{ 'common.name_label' | translate }} <p-sortIcon field="fam_nom"></p-sortIcon>
			</th>

			<th class="text-center">
				{{ 'common.description_label' | translate }}
			</th>

			<th></th>
		</tr>
	</ng-template>

	<!-- Message table vide -->
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">
				{{ 'common.no_table_results' | translate }}
			</td>
		</tr>
	</ng-template>


	<ng-template pTemplate="body" let-famille>
		<tr [ngClass]="{'inactive': !famille.fam_actif}">
			<td>{{famille.fam_nom}}</td>
			<td>{{famille.fam_description}}</td>

			<td class="nowrap" *ngIf="!readonly">
				<a
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					uiSref=".single"
					[uiParams]="{fam_id: famille.fam_id}"
				></a>

				<button
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					(click)="deleteFamille(famille)"
					*ngIf="canEdit"
				></button>
			</td>
		</tr>
	</ng-template>

</p-table>

