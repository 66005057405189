import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateService } from '@codeandweb/ngx-translate';

import { StateService, UIRouterModule } from '@uirouter/angular';


import { MenuModule } from '@app/primeng-overrides/menu';
import { Table, TableModule } from '@app/primeng-overrides/table';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';

import { PLACEHOLDER_NAME } from '@app/_global/constantes-langages';
import { EventManagerService } from '@app/_global/event-manager.service';
import { Abonne } from '@app/abonne/abonne.model';
import { AbonneService } from '@app/abonne/abonne.service';
import { FamilleService } from '@app/famille/famille.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { GlobalModule } from '@global/global.module';
import { StorageService } from '@global/storage.service';



import { createDownloadFromHttpResponse, ExtensibleObject, loadTranslations } from '@app/_helpers/utils';
import { Famille } from '@app/famille/famille.model';
import { environment } from '@environments/environment';

const REQUIRED_TRANSLATIONS = [
	'common.create_action_label',
	'common.export_action_label',
	'families.deletion_confirmation',
	'families.deletion_success',
	'families.export_family_list'
];

@Component({
	selector: 'liste-familles',
	templateUrl: `liste-familles.component.html`
})
export class ListeFamillesComponent implements OnInit {

	@ViewChild('tableFamilles', { static: true }) table: Table;
	@Input() me: Utilisateur;
	@Input() abonne_courant: Abonne;

	values: Famille[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = environment.default_rows;

	params: { [key: string]: any } = {
		search: null,
		fam_actif: true
	};

	menuItems: MenuItem[] = [];
	canEdit = false;
	_translations: ExtensibleObject = {};

	constructor(
		private storageService: StorageService,
		private stateService: StateService,
		private familleService: FamilleService,
		private translate: TranslateService,
		private abonneService: AbonneService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService
	) {

		loadTranslations(translate, this._translations, REQUIRED_TRANSLATIONS, (isFirstLoad: boolean) => {
			if (!isFirstLoad) {
				this.initializeMenu();
			}
		});
	}

	private initializeMenu() {
		if (this.canEdit) {
			this.menuItems = [
				{
					label: this._translations['common.create_action_label'],
					icon: 'pi pi-plus',
					command: () => {
						this.stateService.go('.create');
					}
				}
			];
		}

		this.menuItems.push({
			label: this._translations['common.export_action_label'],
			icon: 'pi pi-download',
			command: () => {
				this.exportFamilleList();
			}
		});
	}

	ngOnInit(): void {
		this.canEdit = this.me.uti_administrateur;
		this.initializeMenu();
		this.getParamSet();
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (typeof tableParams[prop] != 'undefined') this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		this.table.rows = this.rows;
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);

			const uiParams: any = Object.assign({}, this.stateService.params, this.params, {
				sit_actif: undefined
			});
			this.stateService.go('.', uiParams, { reload: false, location: 'replace', inherit: false });
		}
	}

	resetSearch() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	loadFamilles(event: LazyLoadEvent) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => {
			this.loading = true;
		});

		const tmpParams = Object.assign({}, event, this.params);

		this.saveParamSet();

		this.familleService
			.getFamilles(tmpParams)
			.subscribe({
				next: ({ familles, total }) => {
					this.totalRecords = total;
					this.values = familles;

					// si on revient sur la liste paginée et que la page en question est vide, on reset
					if (this.values.length === 0 && this.table.first != 0) {
						this.refresh();
					}
				}
			})
			.add(() => {
				this.loading = false;
			});
	}

	deleteFamille(famille: Famille) {
		let message = this._translations['families.deletion_confirmation']
			.replace(PLACEHOLDER_NAME, '<b>' + famille.fam_nom + '</b>');

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.familleService.deleteFamille(famille.fam_id).subscribe({
					complete: () => {
						this.eventManager.emit('toast', { severity: 'success', summary: this._translations['families.deletion_success'] });
						this.refresh();
					}
				});
			}
		});
	}

	exportFamilleList(){
		this.familleService.exportFamilles(this.params)
		.subscribe(response => {
			createDownloadFromHttpResponse(response);
		});
	}

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		TableModule,
		ToolbarModule,
		MenuModule
	],
	exports: [ListeFamillesComponent],
	declarations: [ListeFamillesComponent]
})
export class ListeFamillesModule { }
