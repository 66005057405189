import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { TranslateDirective, TranslateModule, TranslatePipe } from '@codeandweb/ngx-translate';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from '@app/primeng-overrides/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';

import { DebounceDirective } from '@helpers/debounce.directive';
import { GZInputNumberFormatterDirective } from '@helpers/gz-input-number-formatter.directive';
import { FormatSizePipe } from '@helpers/format-size.pipe';
import { MonthPipe } from '@helpers/month.pipe';
import { SwitchMultiCasePipe } from '@helpers/switch-multi-case.pipe';
import { UtilisateurPipe } from '@app/utilisateur/utilisateur.pipe';
import { VarDirective } from '@helpers/ng-var.directive';
import { NumberDisplayModule } from '@app/components/number-display';
import { LazyLoadImgDirective } from '@helpers/lazy-load-img.directive';

@NgModule({
	imports: [
		ButtonModule,
		CardModule,
		CheckboxModule,
		CommonModule,
		ConfirmDialogModule,
		DynamicDialogModule,
		InputTextModule,
		MessageModule,
		MessagesModule,
		ProgressBarModule,
		ScrollTopModule,
		SidebarModule,
		ToastModule,
		NumberDisplayModule,
		TranslatePipe,
		TranslateDirective,
	],
	providers: [DatePipe],
	declarations: [
		LazyLoadImgDirective,
		DebounceDirective,
		FormatSizePipe,
		GZInputNumberFormatterDirective,
		MonthPipe,
		SwitchMultiCasePipe,
		UtilisateurPipe,
		VarDirective,
	],
	exports: [
		ButtonModule,
		CardModule,
		CheckboxModule,
		CommonModule,
		ConfirmDialogModule,
		DynamicDialogModule,
		InputTextModule,
		MessageModule,
		MessagesModule,
		ProgressBarModule,
		ScrollTopModule,
		SidebarModule,
		ToastModule,
		LazyLoadImgDirective,
		DebounceDirective,
		FormatSizePipe,
		GZInputNumberFormatterDirective,
		MonthPipe,
		NumberDisplayModule,
		SwitchMultiCasePipe,
		UtilisateurPipe,
		TranslateModule,
		TranslatePipe,
		TranslateDirective,
	]
})
export class GlobalModule { }
