import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';

import { UIRouterModule } from '@uirouter/angular';

import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurGeneralTabModule } from '@app/utilisateur/utilisateur-general-tab';
import { GlobalModule } from '@global/global.module';


@Component({
	selector: 'utilisateur-creation',
	template: `
		<utilisateur-general-tab class="block m-3" [me]="me"></utilisateur-general-tab>
	`
})
export class UtilisateurCreationComponent {

	@Input() me: Utilisateur;

}

@NgModule({
	imports: [
		CommonModule,
		GlobalModule,
		UIRouterModule,
		UtilisateurGeneralTabModule
	],
	exports: [UtilisateurCreationComponent],
	declarations: [UtilisateurCreationComponent]
})
export class UtilisateurCreationModule { }
