import { StateService, Transition } from '@uirouter/core';

import { AppMenuService } from '@global/app-menu.service';

import { isEmpty } from '@helpers/utils';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

// empty the sidebar menu
export const resetMenuResolve = {
	token: 'resetMenu',
	deps: [AppMenuService, Transition],
	resolveFn: (appMenuService: AppMenuService, transition: Transition) => {
		// console.log('resetMenuResolve');
		appMenuService.setMenuItems([]);
		return true;
	},
};

export const getMeResolve = {
	token: 'me',
	deps: [StateService, UtilisateurService],
	resolveFn: (stateService: StateService, utilisateurService: UtilisateurService) => {
		// console.log('getMeResolve');
		return utilisateurService.getCurrentUtilisateur().toPromise()
			.then(
				(me: any) => {
					return me;
				},
				(error: any) => {
					if (error.status && error.status != 401) {
						stateService.go('service_unavailable', { error: 'true' });
					}
				},
			);
	},
};

// function to determine where the user should be redirected
// after having been redirected to the login form
export const returnToResolve = {
	token: 'returnTo',
	deps: [StateService, Transition],
	resolveFn: (stateService: StateService, transition: Transition) => {
		// console.log('returnToResolve', transition.to().name, transition.from().name, transition.redirectedFrom());

		// The user was redirected to the login state (e.g., via the requiresAuth hook when trying to activate a state)
		if (transition.redirectedFrom() != null) {
			// Return to the original attempted target state (e.g., state.name)
			return transition.redirectedFrom().targetState();
		}

		// The user was not redirected to the login state; they directly activated the login state somehow.
		// Return them to the state they came from.
		if (transition.from().name !== '' && isEmpty(transition.options().custom)) {
			return stateService.target(transition.from(), transition.params('from'));
		}

		// If the fromState's name is empty, then this was the initial transition. Just return them to the home state
		return stateService.target('/root');
	},
};