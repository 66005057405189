<div class="flex align-items-center mb-3">
	<!-- Titre -->
	<div class="p-panel-title text-xl font-bold">
		<ng-container>
			{{ 'abonnes.abonnes_list_page_title' | translate }}
		</ng-container>
	</div>
</div>

<p-toolbar styleClass="mb-4">
	<ng-template pTemplate="left">
		<!-- Recherche -->
		<div class="p-inputgroup mr-2">
			<input
				pInputText
				type="text"
				[(ngModel)]="params.search"
				(onDebounce)="refresh()"
				[attr.placeholder]="'common.search_input_placeholder' | translate"
			/>
			<button type="button" class="p-inputgroup-addon" icon="pi pi-times" pButton label="" [disabled]="!params.search || !params.search" (click)="resetSearch()"></button>
		</div>

		<!-- Coche Actif/Inactif -->
		<div class="col-fixed flex flex-column justify-content-end">
			<p-checkbox
				name="abo_actif"
				inputId="abo_actif"
				[(ngModel)]="params.abo_actif"
				[binary]="true"
				[label]="'abonnes.inactive_state_plural' | translate"
				[trueValue]="false"
				[falseValue]="true"
				(ngModelChange)="refresh()"
			></p-checkbox>
		</div>
	</ng-template>

	<ng-template pTemplate="right">
		<button
			type="button"
			pButton
			icon="pi pi-bars"
			[label]="'common.actions_button_label' | translate"
			class="p-panel-header-icon p-link"
			(click)="menu_list_abonnes.toggle($event)"
			*ngIf="menuItems.length"
		></button>
		<p-menu #menu_list_abonnes [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
	</ng-template>
</p-toolbar>
<p-table
	#tableAbonnes
	[value]="values"
	[lazy]="true"
	(onLazyLoad)="loadAbonnes($event)"
	[loading]="loading"
	[paginator]="true"
	[rows]="rows"
	[showCurrentPageReport]="true"
	[totalRecords]="totalRecords"
	currentPageReportTemplate="{{totalRecords | number}} élément(s)"
	[rowsPerPageOptions]="[10,25,50]"
	styleClass="p-datatable-sm p-datatable-gridlines table-scrollable-hover"
	[autoLayout]="true"
	responsiveLayout="scroll"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="col-logo hide-sm"></th>

			<th class="text-center" pSortableColumn="abo_nom_abonne">
				{{ 'abonnes.abonne_name_label' | translate }} <p-sortIcon field="grc_libelle"></p-sortIcon>
			</th>
			<th class="text-center">{{ 'common.company_label' | translate }}</th>
			<th class="text-center">{{ 'abonnes.abonnement_families_label' | translate }}</th>

			<th class="text-center nowrap">{{ 'abonnes.abonnement_expiration_label' | translate }}</th>
			<th class="text-center nowrap">{{ 'abonnes.active_users_label' | translate }}</th>
			<th class="text-center nowrap">{{ 'abonnes.active_sites_label' | translate }}</th>

			<th></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr>
			<td class="text-center" colspan="100">
				{{ 'common.no_table_results' | translate }}
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-abonne>
		<tr [ngClass]="{'inactive': !abonne.abo_actif}">
			<td class="col-logo hide-sm">
				<logo-display [abonne]="abonne"></logo-display>
			</td>

			<td>{{abonne.abo_nom_abonne}}</td>
			<td>{{abonne.abo_nom_societe}}</td>
			<td>{{formatFamilyList(abonne)}}</td>

			<td>{{abonne.abo_date_expiration | date:'dd MMMM yyyy'}}</td>

			<td class="text-right nowrap">
				<span
					*ngIf="abonne.abo_nombre_utilisateurs != null"
					[ngClass]="(abonne.nombre_utilisateurs_actifs > abonne.abo_nombre_utilisateurs) ? 'text-color-danger font-bold' : ''"
				>
					{{abonne.nombre_utilisateurs_actifs}} / {{abonne.abo_nombre_utilisateurs ?? 0}}
				</span>

				<span *ngIf="abonne.abo_nombre_utilisateurs == null">
					{{abonne.nombre_utilisateurs_actifs}}
				</span>
			</td>

			<td class="text-right nowrap">
				<span
					*ngIf="abonne.abo_nombre_sites != null"
					[ngClass]="(abonne.nombre_sites_actifs > abonne.abo_nombre_sites) ? 'text-color-danger font-bold' : ''"
				>
					{{abonne.nombre_sites_actifs}} / {{abonne.abo_nombre_sites ?? 0}}
				</span>

				<span *ngIf="abonne.abo_nombre_sites == null">
					{{abonne.nombre_sites_actifs}}
				</span>
			</td>

			<td class="nowrap" *ngIf="!readonly">
				<a
					pButton
					icon="pi pi-eye"
					class="p-button-rounded p-button-text"
					uiSref="abonnes.single"
					[uiParams]="{abo_id: abonne.abo_id}"
				></a>
				<button
					type="button"
					pButton
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					(click)="deleteAbonne(abonne)"
					*ngIf="canEdit"
				></button>
			</td>
		</tr>
	</ng-template>
</p-table>

